import { FC, useMemo } from 'react';
import { barLabelFontSize, barRadius, barWidth } from '../../../utils/var';
import Echarts from '../../ECharts';
import colorPalette from '../../ECharts/themes/colorPalette';

interface PopulationProps {
  data: any;
}
const Population: FC<PopulationProps> = ({ data }) => {
  const option = useMemo(() => {
    const xAxisItems = data.map((d) => d.name);

    return {
      //画布背景色设置
      backgroundColor: 'transparent',
      title: {
        show: true,
        text: '5♡分布',
        left: 'center',
        top: '16px',
        textStyle: {
          fontSize: 24,
          color: '#fff',
        },
      },
      tooltip: {
        show: false,
      },
      legend: {
        show: false,
      },
      toolbox: {
        show: false,
      },
      calculable: true,
      grid: {
        show: false,
      },
      xAxis: [
        {
          type: 'category',
          data: xAxisItems,
          nameTextStyle: {
            color: '#fff',
          },
          axisLine: {
            //y轴
            show: false,
          },
          axisTick: {
            show: false,
          },
          axisLabel: {
            color: '#ffff',
          },
          splitArea: {
            show: false,
          },
        },
      ],
      yAxis: [
        {
          type: 'value',
          name: '人数',
          minInterval: 1,
          nameLocation: 'end',
          axisLabel: {
            color: '#ffff',
          },
          axisLine: {
            //y轴
            show: false,
          },
          axisTick: {
            //刻度线
            show: false,
          },
          splitLine: {
            //网格线
            show: true,
            lineStyle: {
              color: 'rgba(255,255,255,.2)',
            },
          },
          splitArea: {
            show: false,
          },
        },
      ],
      series: [
        {
          name: '人数',
          type: 'bar',
          //设置柱状图宽度
          barWidth,
          data,
          label: {
            show: true,
            color: '#fff',
            position: 'top',
            formatter: '{c}人',
            fontSize: barLabelFontSize,
          },
          itemStyle: {
            //柱形图圆角，顺时针左上，右上，右下，左下）
            barBorderRadius: barRadius,
            color(param: any) {
              return colorPalette[param.dataIndex];
            },
          },
        },
      ],
    };
  }, [data]);
  return <Echarts height={465} option={option} />;
};
Population.displayName = 'Population';
export default Population;
