import { FC } from 'react';

interface FiveLoveSymbolProps {
  count: number;
}
const FiveLoveSymbol: FC<FiveLoveSymbolProps> = ({ count }) => {
  return <span>{count}♡</span>;
};
FiveLoveSymbol.displayName = 'FiveLoveSymbol';
export default FiveLoveSymbol;
