import { FC, useMemo } from 'react';
import Echarts from '../../../ECharts';
import { mapOptions } from './options';
import { ScrollBoard } from '@jiaminghi/data-view-react';
import { BorderBox3 } from '@jiaminghi/data-view-react';

interface MapProps {
  data: any;
}
const Map: FC<MapProps> = ({ data }) => {
  const sortedData = useMemo(() => data.sort((a, b) => b.gradePoint - a.gradePoint), [data]);
  const options = useMemo(() => {
    return mapOptions(sortedData);
  }, [sortedData]);
  const config = useMemo(() => {
    return {
      // 表头背景色
      headerBGC: '#443dc5',
      // 奇数行背景色
      oddRowBGC: '#09184F',
      // 偶数行背景色
      evenRowBGC: '#070C34',
      // 行号
      index: true,
      // 行号表头
      indexHeader: '序号',
      // 宽度
      columnWidth: [50, 100, 100, 100],
      // 对其方式
      align: ['center', 'center', 'center'],
      // 表行数
      rowNum: 10,
      header: ['省份', '生源人数', '平均绩点'],
      data: sortedData.map(({ name, students, gradePoint }) => [name, students, gradePoint]),
    };
  }, [sortedData]);
  return (
    <div
      style={{
        width: 1160,
        position: 'relative',
      }}
    >
      <Echarts option={options} height={780} />
      <ScrollBoard
        config={config}
        style={{
          width: '350px',
          height: '405px',
          position: 'absolute',
          left: '16px',
          bottom: '0px',
        }}
      />
    </div>
  );
};
Map.displayName = 'Map';
export default Map;
