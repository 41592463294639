export const barWidth = 24;
export const barLabelFontSize = 18;
export const barRadius = [3, 3, 3, 3];
export const lineLabelFontSize = 16;
export const lineEndLabel = {
  endLabel: {
    show: true,
    color: '#fff',
    formatter: '{a}',
  },
};
