import { createRoot } from 'react-dom/client';
import StarRatings from 'react-star-ratings';

class XStar extends HTMLElement {
  connectedCallback() {
    const mountPoint = document.createElement('div');
    this.attachShadow({ mode: 'open' }).appendChild(mountPoint);

    const rating = this.getAttribute('rating');
    const max = this.getAttribute('max');
    const dimension = this.getAttribute('dimension');
    const spacing = this.getAttribute('spacing');
    const root = createRoot(mountPoint);
    root.render(
      <StarRatings
        rating={Number(rating)}
        starRatedColor="yellow"
        numberOfStars={Number(max)}
        name="rating"
        starDimension={dimension || '16px'}
        starSpacing={spacing || '4px'}
      />,
    );
  }
}
customElements.define('x-star', XStar);
