import styled from 'styled-components';
import pageBg from '../../assets/pageBg.png';
import { FC, useCallback, useEffect } from 'react';
import TopPage from './TopPage';
import _ from 'lodash';
import logo from './logo.png';
import styles from './index.module.scss';

export const IndexPageStyle = styled.div`
  position: relative;
  overflow: hidden;
  margin: 0px;
  padding: 10px 0 0 0;
  background: url(${pageBg}) center center no-repeat;
  background-size: cover;
`;
export const IndexPageContent = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
  .center-page {
    flex: 1;
  }
`;

interface PageProps {
  children: any;
  backgroundImage?: string;
  title?: React.ReactNode;
}
function getScale() {
  return (window.innerWidth / 1920).toFixed(4);
}
document.body.style.transformOrigin = 'left top';
document.body.style.backgroundSize = '100%';
document.body.style.width = '1920px';
document.body.style.height = '1080px';
document.body.style.transform = `scale(${getScale()})`;
const handleResize = _.debounce(() => {
  document.body.style.transform = `scale(${getScale()})`;
}, 300);
window.addEventListener('resize', handleResize);
const Page: FC<PageProps> = ({ children, title, backgroundImage }) => {
  useEffect(() => {
    document.body.style.backgroundImage = `url(${backgroundImage})`;
    document.body.style.backgroundColor = 'rgba(13, 42, 67, 0)';
  }, []);
  return (
    <IndexPageStyle>
      <TopPage title={title} />
      <img src={logo} className={styles.logo} />
      {children}
    </IndexPageStyle>
  );
};
Page.displayName = 'Page';
Page.defaultProps = {
  backgroundImage: pageBg,
};
export default Page;
