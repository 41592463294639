import { FC, useMemo } from 'react';
import { barLabelFontSize, barRadius, barWidth } from '../../../utils/var';
import Echarts from '../../ECharts';
import colorPalette from '../../ECharts/themes/colorPalette';
import useCarouselData from '../../hooks/useCarouselData';
interface PopulationProps {
  data: any[];
  msg: String;
  changeCount: Function;
}
const Population: FC<PopulationProps> = (props: PopulationProps) => {
  const { data, msg, changeCount } = props;
  const [activeData] = useCarouselData({ data, interval: 8000 });
  const rainbowColor = [
    '#ff0000', '#ffa500', '#ffff00', '#00ff00', '#007fff', '#0000ff', '#8b00ff',
  ];
  const option = useMemo(() => {
    // const xAxisItems = [activeData].values.map((d) => d.name);
    const xAsisItems = activeData.values.map((d) => d.name);
    changeCount(activeData.year);
    let data = activeData.values.map((item) => {
      return [item.name.replace(/（专业学位）/,''), ...item.tValues.map(legend => {
        return legend.value
      })]
    })
    data.unshift(['name', '德育', '智育', '体育', '美育', '劳育', '创新', '先锋'])
    return {
      //画布背景色设置
      backgroundColor: 'transparent',
      title: {
        show: true,
        text: '',
        left: 'center',
        top: '16px',
        textStyle: {
          fontSize: 24,
          color: '#fff',
        },
      },
      tooltip: {
        show: false,
      },
      color:rainbowColor,
      legend: {
        show: true,
        textStyle: {
          color: '#fff'
        },
        y: '230',
      },
      toolbox: {
        show: false,
      },
      calculable: true,
      grid: {
        bottom: 90,
        left:60
      },
      xAxis: [
        {
          type: 'category',
          nameTextStyle: {
            color: '#fff',
          },
          axisLine: {
            //y轴
            show: false,
          },
          axisTick: {
            show: false,
          },
          axisLabel: {
            color: '#ffff',
            rotate:20,
            align:'center',
            margin:20
          },
          splitArea: {
            show: false,
          },
        },
      ],
      yAxis: [
        {
          type: 'value',
          name: '分值',
          minInterval: 1,
          nameLocation: 'end',
          axisLabel: {
            color: '#ffff',
          },
          axisLine: {
            //y轴
            show: false,
          },
          axisTick: {
            //刻度线
            show: false,
          },
          splitLine: {
            //网格线
            show: true,
            lineStyle: {
              color: 'rgba(255,255,255,.2)',
            },
          },
          splitArea: {
            show: false,
          },
        },
      ],
      dataset: {
        source: data
      },

      series: ['德育', '智育', '体育', '美育', '劳育', '创新', '先锋'].map(() => {
        return {
          type: 'bar',
          //设置柱状图宽度
          barWidth: 6,
          label: {
            show: false,
            color: '#fff',
            position: 'top',
            fontSize: barLabelFontSize,
          },
          itemStyle: {
            //柱形图圆角，顺时针左上，右上，右下，左下）
            barBorderRadius: barRadius,
          },
        };
      })
    };
  }, [activeData.values]);
  return <Echarts height={270} option={option} />;
};
Population.displayName = 'Population';
export default Population;
