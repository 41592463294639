import { FC, useEffect, useMemo, useState } from 'react';
import AnimatedNumber from '../../Animatied/Number';
import AnimatedScrollIntoText from '../../Animatied/ScrollIntoText';
import Echarts from '../../ECharts';
import styles from './GenderChart.module.scss';

interface GenderChartProps {
  data: any[];
  className?: string;
  category: string;
}
const _color = ['rgba(255,255,255,0.1)', 'rgb(255,109,135)', 'rgba(60, 157, 228, 1)', '#01aae5', '#fff'];
const GenderChart: FC<GenderChartProps> = ({ data, category }) => {
  const [active, setActive] = useState(0);
  useEffect(() => {
    setActive(0);
    let timer;
    if (data) {
      timer = setInterval(() => {
        setActive((prev) => {
          const next = prev + 1;
          return next >= data.length ? 0 : next;
        });
      }, 2500);
    }
    return () => {
      clearInterval(timer);
    };
  }, [data]);
  const option = useMemo(() => {
    if (!Array.isArray(data)) {
      return {};
    }
    return {
      background: 'transparent',
      series: [
        {
          name: '',
          type: 'pie',
          center: ['50%', 'center'],
          radius: ['60%', '65%'],
          avoidLabelOverlap: false,
          data: data.map((element: any, index: any) => {
            return Object.assign(
              index === 0 ? { itemStyle: { color: `${_color[1]}` } } : { itemStyle: { color: `${_color[0]}` } },
              element,
            );
          }),
          label: {
            show: true,
            color: '#fff',
            fontSize: 16,
            padding: [0, 12, 0, 12],
            formatter: '{bigger|{b}}\n\n{d}%\n\n{c}人',
            rich: {
              bigger: {
                fontSize: 22,
              },
            },
          },
          labelLine: {
            show: false,
          },
          silent: true,
        },
        {
          name: '中圈',
          type: 'pie',
          center: ['50%', 'center'],
          radius: ['70%', '75%'],
          avoidLabelOverlap: false,
          label: {
            show: true,
            position: 'center',
            color: '#fff',
            fontSize: 24,
            formatter: category,
          },
          silent: true,
          data: data.map((element, index) => {
            return Object.assign(
              index === 1 ? { itemStyle: { color: `${_color[2]}` } } : { itemStyle: { color: `${_color[0]}` } },
              element,
            );
          }),
        },
      ],
    };
  }, [data]);
  return (
    <div className={styles.wrap}>
      <Echarts height={275} width="auto" option={option} />
    </div>
  );
};
GenderChart.displayName = 'GenderChart';
export default GenderChart;
