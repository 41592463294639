import { FC, useMemo } from 'react';
import { lineLabelFontSize } from '../../../utils/var';
import Echarts from '../../ECharts';
import useCarouselData from '../../hooks/useCarouselData';

interface FailTestProps {
  data: any;
}

const FailTest2: FC<FailTestProps> = ({ data }) => {
  const [activeData] = useCarouselData<any>({ data, interval: 8000 });
  const option = useMemo(() => {
    const xAxisItems = activeData.grades[0].times.map((g) => g.name);

    return {
      //画布背景色设置
      backgroundColor: 'transparent',
      title: {
        show: false,
        left: 'center',
        top: '0',
        textStyle: {
          fontSize: 16,
          color: '#fff',
        },
        rich: {
          bolder: {
            fontSize: 20,
            fontWeight: 700,
          },
        },
      },
      tooltip: {
        show: false,
      },
      legend: {
        show: true,
        right: 10,
        top: 30,
        data: activeData.grades.map((g) => g.name),
        textStyle: {
          color: '#fff',
        },
      },
      toolbox: {
        show: false,
      },
      calculable: true,
      grid: {
        show: false,
        top: 100,
        bottom: 20,
      },
      xAxis: [
        {
          type: 'category',
          data: xAxisItems,
          nameTextStyle: {
            color: '#fff',
          },
          axisLine: {
            //y轴
            show: false,
          },
          axisTick: {
            show: false,
          },
          axisLabel: {
            color: '#ffff',
          },
          splitArea: {
            show: false,
          },
        },
      ],
      yAxis: [
        {
          name: '不及格人数',
          type: 'value',
          axisLine: {
            //y轴
            show: false,
          },
          axisTick: {
            //刻度线
            show: false,
          },
          splitLine: {
            //网格线
            show: false,
          },
          splitArea: {
            show: false,
          },
          axisLabel: {
            textStyle: {
              color: '#fff',
              fontSize: 12,
              padding: [0, 10, 0, 0],
            },
          },
        },
      ],
      series: activeData.grades.map((warning, index) => {
        return {
          name: warning.name,
          type: 'line',
          label: {
            show: true,
            color: '#fff',
            position: 'left',
            formatter: '{c}',
            fontSize: lineLabelFontSize,
            // formatter: ({ dataIndex, seriesName, value }) => {
            //   if (dataIndex === 0) {
            //     return `${seriesName} ${value}`;
            //   }
            //   return `${value}`;
            // },
          },
          endLabel: {
            show: true,
            color: '#fff',
            formatter: '{a}',
          },
          data: warning.times,
        };
      }),
    };
  }, [activeData]);
  const match = useMemo(() => activeData.name.match(/^([^\d]*)(\d+)([^\d]*)$/), [activeData]);
  return (
    <div style={{ position: 'relative' }}>
      <div
        style={{
          position: 'absolute',
          left: '50%',
          transform: 'translate(-50%)',
          top: '10px',
          color: '#fff',
          fontSize: 16,
        }}
      >
        {match ? (
          <>
            <span>{match[1]}</span>
            <span style={{ fontSize: 24, verticalAlign: 'center', margin: '0 6px' }}>{match[2]}</span>
            <span>{match[3]}</span>
          </>
        ) : (
          `${activeData.name}人数同期对比`
        )}
      </div>
      <Echarts height={280} option={option} />
    </div>
  );
};
FailTest2.displayName = 'FailTest';
export default FailTest2;
