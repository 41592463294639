import { FC } from 'react';
import { Building } from './interface';
import StarRatings from 'react-star-ratings';
import styles from './Ratings.module.scss';

interface RatingsProps {
  building: Building;
}
const Ratings: FC<RatingsProps> = ({ building }) => {
  return (
    <div className={styles.flexBox}>
      <div className={styles.flexItem}>
      {Array.isArray(building?.exponents)
        ? building.exponents.slice(0,4).map((exp) => {
            return (
              <div className={styles.row} key={exp.name}>
                <div className={styles.title}>{exp.name}</div>
                <StarRatings
                  rating={exp.value}
                  starRatedColor="yellow"
                  numberOfStars={5}
                  name="rating"
                  starDimension="23px"
                  starSpacing="6px"
                />
                <div className={styles.score}>{exp.value}</div>
              </div>
            );
          })
        : null}
      </div>
      <div className={styles.flexItem}>
      {Array.isArray(building?.exponents)
        ? building.exponents.slice(4,7).map((exp) => {
            return (
              <div className={styles.row} key={exp.name}>
                <div className={styles.title}>{exp.name}</div>
                <StarRatings
                  rating={exp.value}
                  starRatedColor="yellow"
                  numberOfStars={5}
                  name="rating"
                  starDimension="23px"
                  starSpacing="6px"
                />
                <div className={styles.score}>{exp.value}</div>
              </div>
            );
          })
        : null}
      </div>
    </div>
  );
};
Ratings.displayName = 'Ratings';
export default Ratings;
