import { FC, useMemo } from 'react';
import { ScrollBoard } from '@jiaminghi/data-view-react';
interface FortressProps {
  data: any[];
}

const starsMap = {
  一星: '<x-star rating="1" max="1" dimension="14px" spacing="2px" />',
  二星: '<x-star rating="2" max="2" dimension="14px" spacing="2px" />',
  三星: '<x-star rating="3" max="3" dimension="14px" spacing="2px" />',
  四星: '<x-star rating="4" max="4" dimension="14px" spacing="2px" />',
  五星: '<x-star rating="5" max="5" dimension="14px" spacing="2px" />',
};

const Fortress: FC<FortressProps> = ({ data }) => {
  const config = useMemo(() => {
    return {
      // 表头背景色
      headerBGC: '#443dc5',
      // 奇数行背景色
      oddRowBGC: '#09184F',
      // 偶数行背景色
      evenRowBGC: '#070C34',
      // 行号
      index: false,
      // 行号表头
      indexHeader: '序号',
      // 宽度
      columnWidth: [500, 300],
      // 对其方式
      align: ['center', 'center'],
      // 表行数
      rowNum: 10,
      header: ['党支部名称', '堡垒指数'],
      data: data.map(([name, star]) => [name, starsMap[star] || star]),
      // carousel: 'page',
    };
  }, [data]);
  return (
    <ScrollBoard
      config={config}
      style={{
        width: '700px',
        height: '270px',
        margin: '0 auto',
      }}
    />
  );
};
Fortress.displayName = 'Fortress';
export default Fortress;
