import { FC } from 'react';
import styles from './index.module.scss';
import { BorderBox13 } from '@jiaminghi/data-view-react';

interface DataProps {
  data: any;
}
const Data: FC<DataProps> = ({ data }) => {
  return (
    <>
      {data.map((d) => {
        if (!Array.isArray(d)) {
          return (
            <div className={styles.data} key={d.name}>
              <BorderBox13>
                <div className={styles.dataInner}>
                  <div className={styles.dataName}>{d.name}</div>
                  <div className={styles.dataValue}>{d.value}</div>
                </div>
              </BorderBox13>
            </div>
          );
        }
        let outKey = '';
        const inner = (
          <BorderBox13>
            {d.map((nestedD) => {
              outKey += `-${nestedD.name}-`;
              return (
                <div style={{ height: 43, paddingTop: 15 }} className={styles.dataInner} key={nestedD.name}>
                  <div className={styles.dataName}>{nestedD.name}</div>
                  <div className={styles.dataValue}>{nestedD.value}</div>
                </div>
              );
            })}
          </BorderBox13>
        );
        return (
          <div style={{ height: d.length * 48 }} key={outKey} className={styles.data}>
            {inner}
          </div>
        );
      })}
    </>
  );
};
Data.displayName = 'Data';
export default Data;
