import { useRequest } from 'ahooks';
import axios from 'axios';
import _ from 'lodash';
import { FC } from 'react';

export default function withRemoteData(ComponentFC: FC, request: () => Promise<any>) {
  return () => {
    const { data } = useRequest(request);
    if (!data) {
      return null;
    }
    return <ComponentFC />;
  };
}
export function replaceGlobalDataWith(apiUrl: string, key: string) {
  return axios.get(apiUrl).then(({ data: { data } }) => {
    if (data) {
      _.set(window.__data__, key, data);
    }
    return data;
  });
}
