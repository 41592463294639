import { FC, useMemo } from 'react';
import Echarts from '../../ECharts';
import useCarouselData from '../../hooks/useCarouselData';

interface ThesisProps {
  data: any;
}
const Thesis: FC<ThesisProps> = ({ data: propData }) => {
  const [data] = useCarouselData<any>({ data: propData, interval: 4000 });
  const option = useMemo(() => {
    // const percents: any = {};
    // const values = data.map((item) => item.value);
    // data.forEach((item, index) => {
    //   percents[item.name] = getPercentWithPrecision(values, index, 2);
    // });
    // const total = sum(...data.map((d) => d.value));
    return {
      //画布背景色设置
      backgroundColor: 'transparent',
      title: {
        show: true,
        text: data.name,
        left: 'center',
        top: 0,
        textStyle: {
          fontSize: 18,
          color: '#fff',
        },
      },
      tooltip: {
        show: false,
      },
      legend: {
        show: false,
      },
      toolbox: {
        show: false,
      },
      calculable: true,
      grid: {
        show: false,
      },
      series: {
        silent: true,
        name: '论文层次',
        type: 'pie',
        center: ['50%', '60%'],
        radius: ['43%', '60%'],
        avoidLabelOverlap: true,
        itemStyle: {
          // borderRadius: 10,
          // borderColor: '#fff',
          // borderWidth: 2,
        },
        label: {
          show: true,
          fontSize: 14,
          color: '#fff',
          formatter: '{b}:{c}',
          width: 110,
          overflow: 'break',
        },
        labelLine: {
          show: false,
        },
        data: data.data,
      },
    };
  }, [data]);
  return <Echarts height={250} option={option} />;
};
Thesis.displayName = 'Thesis';
export default Thesis;
