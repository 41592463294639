import { useRequest } from 'ahooks';
import axios from 'axios';
import { FC } from 'react';
import withRemoteData, { replaceGlobalDataWith } from '../../../utils/withRemoteData';
import AnimatedNumber from '../../Animatied/Number';
import AnimatedScrollIntoText from '../../Animatied/ScrollIntoText';
import _BorderBox11 from '../../BorderBox11';
import DataName from '../../Data/Name';
import DataValue from '../../Data/Value';
import Page from '../../Page';
import ValueRingRow from '../../ValueRingRow';
import CET from './CET';
import Courses from './Courses';
import FailTest from './FailTest';
import FailTest2 from './FailTest2';
import GradePointRadar from './GradePointRadar';
import styles from './index.module.scss';
import Truancy from './Truancy';
import WaringGrades from './WaringGrades';

const BorderBox11: any = _BorderBox11;

const daysMap = ['周一', '周二', '周三', '周四', '周五', '周六', '周日'];

function request() {
  return Promise.all([
    axios.get('/api/large/attendance').then(({ data: { data } }) => {
      window.__data__.academicSentimentData.truancy.today = data.Truancy[new Date().getDay()] ?? 0;
      window.__data__.academicSentimentData.truancy.week.total = data.totalTruancy;
      window.__data__.academicSentimentData.truancy.week.deal = data.Handled;
      window.__data__.academicSentimentData.truancy.week.days = data.Truancy.map((value, index) => {
        return {
          name: daysMap[index] ?? '',
          value,
        };
      });
      return data;
    }),
    replaceGlobalDataWith('/api/large/cet', 'academicSentimentData.cet'),
    replaceGlobalDataWith('/api/large/failTest', 'academicSentimentData.failTest'),
    replaceGlobalDataWith('/api/large/failTest2', 'academicSentimentData.failTest2'),
    replaceGlobalDataWith('/api/large/coursesPoints', 'academicSentimentData.coursesPoints'),
    replaceGlobalDataWith('/api/large/gpRadar', 'academicSentimentData.gpRadar'),
    replaceGlobalDataWith('/api/large/warning', 'academicSentimentData.warnings'),
  ]).then((d) => {
    window.__data__.academicSentimentData.cet.total = 100;
    return d;
  });
}

interface AcademicSentimentProps {}
const AcademicSentiment: FC<AcademicSentimentProps> = () => {
  const academicSentimentData = window.__data__.academicSentimentData;
  const { warnings, cet, failTest, failTest2, truancy, helpCourse, gpRadar, coursesPoints } = academicSentimentData;
  return (
    <Page title="学情分析">
      <div className={styles.main}>
        <div className={styles.left}>
          <div className={styles.leftTop}>
            <BorderBox11 title="学业预警">
              <div className={styles.header}>
                {warnings.map((waring) => {
                  return (
                    <div className={styles.headerItem}>
                      <div className={styles.headerItemTitle}>
                        <AnimatedScrollIntoText value={`${waring.name}`} height={25} />
                      </div>
                      <div className={styles.headerItemCount}>
                        <AnimatedNumber value={waring.total} />
                        <span>人</span>
                      </div>
                    </div>
                  );
                })}
              </div>
              <WaringGrades data={warnings} />
            </BorderBox11>
          </div>
          <div className={styles.leftBottom}>
            <BorderBox11 title="四六级通过率">
              <CET data={cet} />
            </BorderBox11>
          </div>
        </div>
        <div className={styles.center}>
          <div className={styles.centerTop}>
            <GradePointRadar data={gpRadar} />
          </div>
          <div className={styles.centercenter}>
            <FailTest data={failTest} />
            {/* <BorderBox11 title="月度变化">
              <MonthPopluation data={window.__data__.care.monthPopluation} />
            </BorderBox11> */}
          </div>
          <div className={styles.centerBottom}>
            <FailTest2 data={failTest2} />
          </div>
        </div>
        <div className={styles.right}>
          <div className={styles.rightTop}>
            <BorderBox11 title="旷课情况">
              <div className={styles.oneRowTitle}>
                <DataName>今日旷课人数</DataName>
                <DataValue>
                  <span>{truancy.today}</span>
                  <span>人</span>
                </DataValue>
              </div>
              <Truancy data={truancy} />
              <div style={{ padding: '12px 20px 0' }}>
                <ValueRingRow
                  height={50}
                  title={`旷课总数${truancy.week.total}`}
                  valueClassName={styles.truancyValue}
                  value={`辅导员已处置${truancy.week.deal}`}
                  percentage={
                    truancy.week.total === 0 ? 100 : Math.round((truancy.week.deal / truancy.week.total) * 10000) / 100
                  }
                />
              </div>
            </BorderBox11>
          </div>
          <div className={styles.rightCenter}>
            <BorderBox11 title="精准帮扶">
              <div className={styles.oneRowTitle}>
                <DataName>课程助教人数</DataName>
                <DataValue>
                  <span>{helpCourse}</span>
                  <span>人</span>
                </DataValue>
              </div>
            </BorderBox11>
          </div>
          <div className={styles.rightBottom}>
            <BorderBox11 title="重点课程">
              <Courses data={coursesPoints} />
            </BorderBox11>
          </div>
        </div>
      </div>
    </Page>
  );
};
AcademicSentiment.displayName = 'FiveLove';
export default withRemoteData(AcademicSentiment, request);
