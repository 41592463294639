import { FC, useMemo } from 'react';
import { barLabelFontSize, lineLabelFontSize } from '../../../utils/var';
import Echarts from '../../ECharts';
import useCarouselData from '../../hooks/useCarouselData';
interface MonthPopluationProps {
  data: any[];
  msg:String;
  changeGrowType:Function;
}
const MonthPopluation: FC<MonthPopluationProps> = ( props:MonthPopluationProps ) => {
  const {data,msg,changeGrowType}=props;
  const [activeData,active] = useCarouselData({ data, interval: 8000 });
  const option = useMemo(() => {
    const legendsSelected: any = {};
    const legends = data.map((d) => {
      legendsSelected[d.name] = d.name === activeData.name;
      return d.name;
    });
    const xAsisItems = activeData.values.map((d) => d.name);
    changeGrowType(activeData.type);
    return {
      //画布背景色设置
      backgroundColor: 'transparent',
      title: {
        show: false,
      },
      tooltip: {
        show: false,
      },
      legend: {
        show: true,
        data: ['个人','同年级同专业平均'],
        right: 150,
        bottom:80,
        textStyle: {
          color: '#fff',
        },
        // selected: legendsSelected,
      },
      toolbox: {
        show: false,
      },
      calculable: true,
      grid: {
        bottom:140
      },
      xAxis: [
        {
          data: xAsisItems,
          type: 'category',
          nameTextStyle: {
            color: '#fff',
          },
          axisLine: {
            //y轴
            show: false,
          },
          axisTick: {
            show: false,
          },
          axisLabel: {
            color: '#ffff',
          },
          splitArea: {
            show: false,
          },
        },
      ],
      yAxis: [
        {
          type: 'value',
          name: '分值',
          minInterval: 1,
          nameLocation: 'end',
          axisLabel: {
            color: '#ffff',
          },
          axisLine: {
            //y轴
            show: false,
          },
          axisTick: {
            //刻度线
            show: false,
          },
          splitLine: {
            //网格线
            show: true,
            lineStyle: {
              color: 'rgba(255,255,255,.2)',
            },
          },
          splitArea: {
            show: false,
          },
          min(value) {
            return 0;
          },
          max(value) {
            return 5;
          },
        },
      ],
      series:  [ {
        name : '个人',
        type : 'line',
        // smooth: true,
        lineStyle : {
            color : '#4d8bfc',
            width : 2,
            type : 'solid',
        },
        //折线连接点样式
        itemStyle : {
            color : '#3084ff'
        },
        data : activeData.values.map(item=>{return item.value})
    }, {
        name : '同年级同专业平均',
        type : 'line',
        // smooth: true,
        lineStyle : {
            color : '#d256b6',
            width : 2,
            type : 'dashed',
        },
        //折线连接点样式
        itemStyle : {
            color : '#d256b6'
        },
        data : activeData.values.map(item=>{return item.average})
    } ],
    };
  }, [activeData.values]);

  return <Echarts height={300} option={option} />;
};
MonthPopluation.displayName = 'MonthPopluation';
export default MonthPopluation;
