import { useEffect, useState } from 'react';
import AMapLoader from '@amap/amap-jsapi-loader';

export default function useAMap() {
  const [AMap, setAMap] = useState<any>(null);
  useEffect(() => {
    AMapLoader.load({
      key: 'e4a1c903142cf33565bd27b6456879d1', // 申请好的Web端开发者Key，首次调用 load 时必填
      version: '2.0', // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
      plugins: ['AMap.GeoJSON'], // 需要使用的的插件列表，如比例尺'AMap.Scale'等
    })
      .then((a) => {
        setAMap(a);
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);
  return AMap;
}
