import { animated, useSpring } from '@react-spring/web';
import { FC } from 'react';

interface AnimatedNumberProps {
  value: number | null | undefined;
  className?: string;
}
const AnimatedNumber: FC<AnimatedNumberProps> = ({ value, className }) => {
  const styles = useSpring({ x: typeof value === 'number' ? value : 0 });
  return <animated.span className={className}>{styles.x.to((x) => x.toFixed(0))}</animated.span>;
};
AnimatedNumber.displayName = 'AnimatedNumber';
export default AnimatedNumber;
