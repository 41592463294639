import { FC, useMemo } from 'react';
import Echarts from '../../ECharts';

interface TreeProps {
  data: any;
}
const Tree: FC<TreeProps> = ({ data }) => {
  const option = useMemo(() => {
    return {
      //画布背景色设置
      backgroundColor: 'transparent',
      title: {
        show: false,
      },
      tooltip: {
        show: false,
      },
      legend: {
        show: false,
      },
      toolbox: {
        show: false,
      },
      calculable: true,
      grid: {
        show: false,
        left: 70,
        right: 20,
      },
      series: [
        {
          type: 'tree',
          data: [data],
          left: '0',
          right: '0',
          top: '8%',
          bottom: '60%',
          symbol: 'emptyCircle',
          orient: 'vertical',
          expandAndCollapse: true,
          label: {
            position: 'top',
            rotate: -90,
            verticalAlign: 'middle',
            align: 'right',
            fontSize: 9,
            color: '#fff',
          },
          leaves: {
            label: {
              position: 'bottom',
              rotate: -90,
              verticalAlign: 'middle',
              align: 'left',
            },
          },
          animationDurationUpdate: 750,
        },
      ],
    };
  }, [data]);
  return <Echarts height={790} option={option} />;
};
Tree.displayName = 'Tree';
export default Tree;
