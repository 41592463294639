import classNames from 'classnames';
import { FC, useMemo } from 'react';
import AnimatedNumber from '../../Animatied/Number';
import AnimatedScrollIntoText from '../../Animatied/ScrollIntoText';
import _BorderBox11 from '../../BorderBox11';
import DataName from '../../Data/Name';
import DataValue from '../../Data/Value';
import useCarouselData from '../../hooks/useCarouselData';
import { ModuleTitle } from '../../ModuleTitle';
import Page from '../../Page';
import ValueRingRow from '../../ValueRingRow';
import { innovateData } from './data';
import styles from './index.module.scss';
import ScientificPrices from './ScientificPrices';
import ScientificResearch from './ScientificResearch';
import ShouHeng from './ShouHeng';
import Thesis from './Thesis';
import metal from './metal.png';
import withRemoteData, { replaceGlobalDataWith } from '../../../utils/withRemoteData';

const BorderBox11: any = _BorderBox11;

interface InnovateProps {}
function request() {
  return Promise.all([
    replaceGlobalDataWith('/api/large/scientificResearch', 'innovateData.scientificResearch'),
    replaceGlobalDataWith('/api/large/thisYearScientificPrices', 'innovateData.thisYearScientificPrices'),
    replaceGlobalDataWith('/api/large/scientificPrices', 'innovateData.scientificPrices'),
    replaceGlobalDataWith('/api/large/thesis', 'innovateData.thesis'),
    replaceGlobalDataWith('/api/large/patent', 'innovateData.patent'),
  ]);
}
const Innovate: FC<InnovateProps> = () => {
  const { shouheng, scientificResearch, scientificPrices, thisYearScientificPrices, thesis, patent } = innovateData;
  return (
    <Page title="创新能力">
      <div className={styles.main}>
        <div className={styles.left}>
          <div className={styles.leftTop}>
            <BorderBox11 title="寿恒讲堂">
              <div className={styles.oneRowTitle}>
                <DataName>寿恒讲堂总期数</DataName>
                <DataValue>
                  <span>{shouheng.total}</span>
                  <span>期</span>
                </DataValue>
              </div>
              <div className={styles.oneRowTitle}>
                <DataName>今年期数</DataName>
                <DataValue>
                  <span>{shouheng.thisYear}</span>
                  <span>期</span>
                </DataValue>
              </div>
              <ShouHeng data={shouheng.data} />
            </BorderBox11>
          </div>
          <div className={styles.leftBottom}>
            <BorderBox11 title="科研小助专业分布">
              <ScientificResearch data={scientificResearch} />
            </BorderBox11>
          </div>
        </div>
        <div className={styles.center}>
          <div className={styles.centerTop}>
            <div className={styles.centerTitle}>今年获奖数</div>
            <div className={styles.thisYearPrice}>
              {thisYearScientificPrices.map(({ name, value }) => {
                return (
                  <div key={name} className={classNames(styles.headerItem)}>
                    <img src={metal} alt="" className={styles.metal} />
                    <DataName className={styles.headerItemTitle} isTotal>
                      {name}
                    </DataName>
                    <DataValue className={styles.headerItemCount} isTotal>
                      {value}
                    </DataValue>
                  </div>
                );
              })}
            </div>
          </div>
          <div className={styles.centerBottom}>
            <ScientificPrices data={scientificPrices} />
          </div>
        </div>
        <div className={styles.right}>
          <div className={styles.rightTop}>
            <BorderBox11 title="学生论文">
              <div className={styles.oneRowTitle}>
                <DataName isTotal>本年度发表论文数</DataName>
                <DataValue isTotal>{thesis.thisYear}</DataValue>
              </div>
              <div className={styles.oneRowTitle}>
                <DataName>本科生发表论文数</DataName>
                <DataValue>{thesis.undergraduate}</DataValue>
              </div>
              <div className={styles.oneRowTitle}>
                <DataName>研究生发表论文数</DataName>
                <DataValue>{thesis.postgraduate}</DataValue>
              </div>
              <Thesis data={thesis.levels} />
            </BorderBox11>
          </div>
          <div className={styles.rightBottom}>
            <BorderBox11 title="学生专利">
              <div className={classNames(styles.oneRowTitle)}>
                <DataName isTotal>学年专利数</DataName>
                <DataValue isTotal>{patent.total}</DataValue>
              </div>
              <div className={styles.oneRowTitle}>
                <DataName>本科生学年专利数</DataName>
                <DataValue>{patent.undergraduate}</DataValue>
              </div>
              <div className={styles.oneRowTitle}>
                <DataName>研究生学年专利数</DataName>
                <DataValue>{patent.postgraduate}</DataValue>
              </div>
              <Thesis data={patent.levels} />
            </BorderBox11>
          </div>
        </div>
      </div>
    </Page>
  );
};
Innovate.displayName = 'FiveLove';
export default withRemoteData(Innovate, request);
