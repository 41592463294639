import { BorderBox11 } from '@jiaminghi/data-view-react';
import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import Page from '../../Page';
import styles from './index.module.scss';
import Map from './Map';
import useAMap from '../../hooks/useAMap';
import useCarouselData from '../../hooks/useCarouselData';
import Ratings from './Ratings';
import Dorms from './Dorms';
import _TabBorderBox11 from '../../TabBorderBox11';
import _ from 'lodash';
import { Campus } from './interface';
import withRemoteData, { replaceGlobalDataWith } from '../../../utils/withRemoteData';

const TabBorderBox11: any = _TabBorderBox11;
interface WeiLaiSheQuProps {}
window._AMapSecurityConfig = {
  securityJsCode: '166b75de595621c3da3b90e65024d40f',
};
const per10DormWaitMS = 5000;
function sumPointsOfDorm(dorm) {
  let res = 0;
  dorm.forEach((d, index) => {
    if (index === 0 || typeof d !== 'number') {
      return;
    }
    res += d;
  });
  return res;
}
function request() {
  return replaceGlobalDataWith('/api/large/campusesData', 'campusesData');
}
const WeiLaiSheQu: FC<WeiLaiSheQuProps> = () => {
  const campuses: Campus[] = window.__data__.campusesData;
  const AMap = useAMap();
  const [activeCampusIndex, setActiveCampusIndex] = useState(0);
  const activeCampus = campuses[activeCampusIndex];
  const handleBuildingCarouselEnd = useCallback(() => {
    setActiveCampusIndex((index) => {
      const nextIndex = index + 1;
      return nextIndex >= campuses.length ? 0 : nextIndex;
    });
  }, []);
  const [activeBuilding] = useCarouselData({
    data: activeCampus.buildings,
    interval: (d) => {
      if (!Array.isArray(d.dorms)) {
        return per10DormWaitMS;
      }
      return Math.ceil(d.dorms.length / 10) * per10DormWaitMS;
    },
    infinity: false,
    onEnd: handleBuildingCarouselEnd,
  });
  const tabs = useMemo(() => campuses.map((c) => c.name), []);
  const lastTenDorms = useMemo(() => {
    if (!Array.isArray(activeBuilding?.dorms)) {
      return null;
    }
    const sorted = activeBuilding.dorms.concat().sort((a, b) => {
      return sumPointsOfDorm(a) - sumPointsOfDorm(b);
    });
    return _.take(sorted, 10);
  }, [activeBuilding]);
  return (
    <Page title="未来社区">
      <div className={styles.main}>
        <div className={styles.left}>
          <TabBorderBox11 title="" tabs={tabs} active={activeCampusIndex}>
            {AMap && activeCampus ? (
              <Map
                className={styles.leftBottom}
                AMap={AMap}
                center={activeCampus.center}
                zoom={activeCampus.zoom}
                building={activeBuilding}
                buildings={activeCampus.buildings}
              />
            ) : null}
          </TabBorderBox11>
          <div className={styles.tabs}></div>
        </div>
        <div className={styles.right}>
          <div className={styles.rightTop}>
            <BorderBox11
              title={
                <>
                  5<tspan fill="#fdff37">★</tspan>指数
                </>
              }
            >
              <div className={styles.ratingsTitle}>
                {activeCampus?.name} {activeBuilding?.name}
              </div>
              <Ratings building={activeBuilding} />
            </BorderBox11>
          </div>
          <div className={styles.rightBottom}>
            <BorderBox11 title="寝室指数">
              {Array.isArray(activeBuilding?.dorms) ? <Dorms data={activeBuilding.dorms} /> : null}
            </BorderBox11>
          </div>
          <div className={styles.rightCenter}>
            <BorderBox11 title="关注寝室">
              {Array.isArray(activeBuilding?.dorms) ? <Dorms data={lastTenDorms} height={250} noIndex /> : null}
            </BorderBox11>
          </div>
        </div>
      </div>
    </Page>
  );
};
WeiLaiSheQu.displayName = 'WeiLaiSheQu';
export default withRemoteData(WeiLaiSheQu, request);
