import _ from 'lodash';
import { FC, useMemo } from 'react';
import Echarts from '../../ECharts';
import { getPercentWithPrecision } from 'echarts/lib/util/number';
import sum from '../../../utils/sum';

interface TriggerProps {
  data: any;
}
const Trigger: FC<TriggerProps> = ({ data }) => {
  const option = useMemo(() => {
    const percents: any = {};
    const values = data.map((item) => item.value);
    data.forEach((item, index) => {
      percents[item.name] = getPercentWithPrecision(values, index, 2);
    });
    const total = sum(...data.map((d) => d.value));
    return {
      //画布背景色设置
      backgroundColor: 'transparent',
      title: {
        show: false,
      },
      tooltip: {
        show: false,
      },
      legend: {
        show: true,
        orient: 'vertical',
        right: '55',
        top: 'center',
        textStyle: {
          fontSize: 14,
          color: '#fff',
          rich: {
            left: {
              width: 70,
              fontSize: 14,
            },
            right: { width: 50, align: 'right', fontSize: 14 },
          },
        },
        formatter(name) {
          const item = _.find(data, { name });
          if (!item) {
            return '';
          }
          return `{left|${name}} {right|${item.value}次}`;
        },
      },
      toolbox: {
        show: false,
      },
      calculable: true,
      grid: {
        show: false,
      },
      series: {
        silent: true,
        name: '主题次数',
        type: 'pie',
        center: ['30%', '50%'],
        radius: ['63%', '90%'],
        avoidLabelOverlap: false,
        itemStyle: {
          // borderRadius: 10,
          // borderColor: '#fff',
          // borderWidth: 2,
        },
        label: {
          show: true,
          fontSize: 20,
          position: 'center',
          color: '#fff',
          formatter: `${total}\n\n5♡+触发总数`,
        },
        emphasis: {
          label: {
            show: false,
            fontSize: '40',
            fontWeight: 'bold',
          },
        },
        labelLine: {
          show: false,
        },
        data: data,
      },
    };
  }, [data]);
  return <Echarts height={230} option={option} />;
};
Trigger.displayName = 'Trigger';
export default Trigger;
