import { FC, useMemo } from 'react';
import { barLabelFontSize, lineLabelFontSize } from '../../../utils/var';
import Echarts from '../../ECharts';
import useCarouselData from '../../hooks/useCarouselData';

interface GrowPopluationProps {
  data: any[];
  msg1:String;
  msg2:String;  
  changeGrow:Function;
}
const GrowPopluation: FC<GrowPopluationProps> = (props:GrowPopluationProps) => {
  const {data,msg1,msg2,changeGrow}=props;
  const [activeData] = useCarouselData({ data, interval: 3000 });
  const option = useMemo(() => {
    // const legendsSelected: any = {};
    const legends = [];
    changeGrow({grow1:activeData.type,grow2:activeData.subject});
    const xAsisItems = activeData.values.map((d) => d.name);
    return {
      //画布背景色设置
      backgroundColor: 'transparent',
      title: {
        show: false,
      },
      tooltip: {
        show: false,
      },
      legend: {
        show: true,
        data: legends,
        right: 10,
        textStyle: {
          color: '#fff',
        },
        // selected: legendsSelected,
      },
      toolbox: {
        show: false,
      },
      calculable: true,
      grid: {
        bottom:60
      },
      xAxis: [
        {
          data: xAsisItems,
          type: 'category',
          nameTextStyle: {
            color: '#fff',
          },
          axisLine: {
            //y轴
            show: false,
          },
          axisTick: {
            show: false,
          },
          axisLabel: {
            color: '#ffff',
          },
          splitArea: {
            show: false,
          },
        },
      ],
      yAxis: [
        {
          type: 'value',
          name: '分值',
          minInterval: 1,
          nameLocation: 'end',
          axisLabel: {
            color: '#ffff',
          },
          axisLine: {
            //y轴
            show: false,
          },
          axisTick: {
            //刻度线
            show: false,
          },
          splitLine: {
            //网格线
            show: true,
            lineStyle: {
              color: 'rgba(255,255,255,.2)',
            },
          },
          splitArea: {
            show: false,
          },
          min(value) {
            return 0;
          },
          max(value) {
            return 5;
          },
        },
      ],
      series: [ {
        name : '本人',
        type : 'line',
        // smooth: true,
        lineStyle : {
            color : '#4d8bfc',
            width : 2,
            type : 'solid',
        },
        //折线连接点样式
        itemStyle : {
            color : '#3084ff'
        },
        data : activeData.values.map(child=>{
          return child.value
        }),
    }, {
        name : '平均',
        type : 'line',
        // smooth: true,
        lineStyle : {
            color : '#d256b6',
            width : 2,
            type : 'dashed',
        },
        //折线连接点样式
        itemStyle : {
            color : '#d256b6'
        },
        data : activeData.values.map(child=>{
          return child.average
        }),
    } ],
    };
  }, [activeData.values]);

  return <Echarts height={270} option={option} />;
};
GrowPopluation.displayName = 'GrowPopluation';
export default GrowPopluation;
