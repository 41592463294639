import { FC, useMemo } from 'react';
import Echarts from '../ECharts';

interface RingProps {
  value: number;
  height: number;
}
const Ring: FC<RingProps> = ({ value, height }) => {
  const options = useMemo(() => {
    return {
      title: {
        text: `${value}%`,
        left: '45%',
        top: '32%',
        textAlign: 'center',
        textStyle: {
          fontSize: '12',
          fontWeight: '500',
          color: '#00ffff',
          textAlign: 'center',
        },
      },
      series: [
        {
          type: 'pie',
          startAngle: 90,
          radius: ['80%', '70%'],
          center: ['50%', '50%'],
          data: [
            {
              value: value,
              name: '',
              itemStyle: {
                normal: {
                  color: '#00ffff',
                  shadowColor: 'rgba(255,255,255,.3)',
                  shadowBlur: 3,
                },
              },
              label: {
                show: false,
              },
              labelLine: {
                normal: {
                  smooth: true,
                  lineStyle: {
                    width: 0,
                  },
                },
              },
              hoverAnimation: false,
            },
            {
              label: {
                show: false,
              },
              labelLine: {
                normal: {
                  smooth: true,
                  lineStyle: {
                    width: 0,
                  },
                },
              },
              value: 100 - value,
              hoverAnimation: true,
              itemStyle: {
                color: 'rgba(79,76,192, 0.3)',
              },
            },
          ],
        },
      ],
    };
  }, [value]);
  return <Echarts height={height} option={options} />;
};
Ring.displayName = 'Ring';
export default Ring;
