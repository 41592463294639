// import './ThreeMap.css';
import React, { Component, useEffect } from 'react'
import * as THREE from 'three'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls'
import { barLabelFontSize, barRadius, barWidth } from '../../../utils/var'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js'
import { RenderPass } from 'three/examples/jsm/postprocessing/RenderPass.js'
import { UnrealBloomPass } from 'three/examples/jsm/postprocessing/UnrealBloomPass.js'
import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer.js'
import { ShaderPass } from 'three/examples/jsm/postprocessing/ShaderPass'
import { GUI } from 'three/examples/jsm/libs/lil-gui.module.min.js'
import {
  DirectionalLight,
  DirectionalLightHelper,
  HemisphereLight,
  HemisphereLightHelper,
} from 'three'
function ThreeMap() {
  useEffect(() => {
    init()
  }, [])
  let camera,
    scene,
    renderer,
    clock,
    mixer,
    composer,
    controls,
    circles = []

  const init = () => {
    scene = new THREE.Scene()
    renderer = new THREE.WebGLRenderer({ alpha: true })
    renderer.setClearColor(0xffffff, 0)
    // scene.background = new THREE.Color('rgba(0, 0, 0,0)')
    camera = new THREE.PerspectiveCamera(45, 350 / 550, 1, 1000)
    camera.position.set(-9, 2, 0)
    // renderer = new THREE.WebGLRenderer({ alpha: true })
    // renderer.setClearAlpha(0)
    renderer.setSize(450, 680)
    // renderer.setClearColor('#000', 0)
    // const axesHelper = new THREE.AxesHelper(5)
    // scene.add(axesHelper)
    // 底座圆环
    for (let i = 2; i < 6; i++) {
      let radius = i / 2 //设置同心圆，只有半径不一样
      let geometry = new THREE.CircleGeometry(radius, 100) //半径，分段数
      let material = new THREE.MeshBasicMaterial({
        color: '#00378b',
        wireframe: true,
      })
      let cycleMesh = new THREE.Mesh(geometry, material)
      cycleMesh.position.set(0, -1.3, 0)
      cycleMesh.rotateX(Math.PI / 2) //默认是绘制在xy平面的，所以这里要旋转下放到xz平面
      cycleMesh.rotateY(-0.2)
      // 底座飞行光点
      scene.add(cycleMesh)
      circles.push(radius)
    }

    // 辉光开始
    const bloomPass = new UnrealBloomPass(
      new THREE.Vector2(350, 550),
      3,
      0.4,
      0.85
    )
    const params = {
      exposure: 1,
      bloomThreshold: 0,
      bloomStrength: 1,
      bloomRadius: 0.2,
    }
    bloomPass.threshold = params.bloomThreshold
    bloomPass.strength = params.bloomStrength
    bloomPass.radius = params.bloomRadius
    const renderScene = new RenderPass(scene, camera)
    composer = new EffectComposer(renderer)
    composer.addPass(renderScene)
    // composer.addPass(bloomPass)
    const loader = new GLTFLoader()
    let wireframeMaterial = new THREE.MeshBasicMaterial({
      color: '#00b7ff',
      wireframe: true,
      opacity: 0.5,
      transparent: true,
    })
    loader.load('people.glb', (gltf) => {
      changeMaterial(
        gltf.scene.children[0].children[0],
        gltf,
        wireframeMaterial
      )
      changeMaterial(
        gltf.scene.children[0].children[1],
        gltf,
        wireframeMaterial
      )
      changeMaterial(
        gltf.scene.children[0].children[2],
        gltf,
        wireframeMaterial
      )
      changeMaterial(
        gltf.scene.children[0].children[3],
        gltf,
        wireframeMaterial
      )
      changeMaterial(
        gltf.scene.children[0].children[4],
        gltf,
        wireframeMaterial
      )
      changeMaterial(
        gltf.scene.children[0].children[5],
        gltf,
        wireframeMaterial
      )
      changeMaterial(
        gltf.scene.children[0].children[6],
        gltf,
        wireframeMaterial
      )
      changeMaterial(
        gltf.scene.children[0].children[7],
        gltf,
        wireframeMaterial
      )
      changeMaterial(
        gltf.scene.children[0].children[8],
        gltf,
        wireframeMaterial
      )
      mixer = startAnimation(
        gltf.scene,
        gltf.animations,
        gltf.animations[1].name
      )
      gltf.scene.scale.set(2.3, 2.3, 2.3)
      gltf.scene.position.x = 0
      gltf.scene.position.y = -1.3
      gltf.scene.position.z = 0
      gltf.scene.rotateX(6.3)
      clock = new THREE.Clock()
      scene.add(gltf.scene)
      initControls()
      addLight()
      animate()
    })
    document.getElementById('walkman').appendChild(renderer.domElement)
  }
  const changeMaterial = (obj, gltf, mtr) => {
    // 处理材质
    let buildMaterial = new THREE.MeshBasicMaterial({
      color: '#009EFF', // 颜色
      transparent: true, // 是否开启使用透明度
      opacity: 0.05, // 透明度
      depthWrite: false, // 关闭深度写入 透视效果
      side: THREE.DoubleSide, // 双面显示
    })
    let lineMaterial = new THREE.LineBasicMaterial({
      color: '#36BCFF',
      transparent: true,
      opacity: 0.3,
      depthWrite: true,
      // linewidth: 1,
      side: THREE.DoubleSide,
    })
    obj.material = mtr
    // if (obj.geometry) {
    //   const edges = new THREE.EdgesGeometry(obj.geometry)
    //   const line = new THREE.LineSegments(
    //     edges,
    //     lineMaterial // 赋线条材质
    //   )
    //   gltf.scene.add(line) // 把每一个mesh生成的线条添加到场景中
    // }
    /* debugger
    if (obj.geometry) {
      const edges = new THREE.EdgesGeometry(obj.geometry)
      const line = new THREE.LineSegments(
        edges,
        lineMaterial // 赋线条材质
      )
      gltf.scene.add(line) // 把每一个mesh生成的线条添加到场景中
    } */
  }
  // 重置变换
  const _renderFrameMesh = (obj) => {
    if (obj.geometry) {
      const edges = new THREE.EdgesGeometry(obj.geometry)
      let color = new THREE.Color(0.1, 0.3, 1)
      let lineBasematerial = new THREE.LineBasicMaterial({
        color: color,
        side: THREE.FrontSide,
        linecap: 'round',
        linejoin: 'round',
      })
      const line = new THREE.LineSegments(edges, lineBasematerial)
      return line
    }
  }
  const startAnimation = (skinnedMesh, animations, animationName) => {
    const mMixer = new THREE.AnimationMixer(skinnedMesh)
    const clip = THREE.AnimationClip.findByName(animations, animationName)
    if (clip) {
      const action = mMixer.clipAction(clip)
      action.play()
    }
    return mMixer
  }
  const animate = () => {
    requestAnimationFrame(animate)
    if (mixer) {
      mixer.update(clock.getDelta())
    }
    composer.render()
    controls.update()
    // renderer.render(scene, camera);
  }
  const initControls = () => {
    controls = new OrbitControls(camera, renderer.domElement)
    // 如果使用animate方法时，将此函数删除
    // 使动画循环使用时阻尼或自转 意思是否有惯性
    controls.enableDamping = true
    // 动态阻尼系数 就是鼠标拖拽旋转灵敏度
    controls.dampingFactor = 0.25
    // 是否可以缩放
    controls.enableZoom = true
    // 是否自动旋转
    controls.autoRotate = true
    controls.autoRotateSpeed = 0
    // 设置相机距离原点的最远距离
    controls.minDistance = 1
    // 设置相机距离原点的最远距离
    controls.maxDistance = 200
    // 是否开启右键拖拽
    controls.enablePan = true
  }
  const addLight = () => {
    let directionalLight = new DirectionalLight(0xffffff, 0.5)
    directionalLight.position.set(10, 50, 10)
    directionalLight.castShadow = true
    let dhelper = new DirectionalLightHelper(directionalLight, 5, 0xff0000)
    let hemisphereLight = new HemisphereLight(0xffffff, 0xffffff, 0.4)
    hemisphereLight.position.set(20, 78, 0)
    let hHelper = new HemisphereLightHelper(hemisphereLight, 5)
    scene.add(directionalLight)
    scene.add(hemisphereLight)
  }
  return <div id="walkman"></div>
}
export default ThreeMap
