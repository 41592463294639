import { FC, useMemo } from 'react';
import { lineEndLabel, lineLabelFontSize } from '../../../utils/var';
import Echarts from '../../ECharts';

interface ScientificPricesProps {
  data: any;
}
const ScientificPrices: FC<ScientificPricesProps> = ({ data }) => {
  const option = useMemo(() => {
    // const legendsSelected: any = {};
    const legends = data.map((d) => {
      // legendsSelected[d.name] = d.name === activeData.name;
      return d.name;
    });
    const xAsisItems = data[0].data.map((d) => d.name);
    return {
      //画布背景色设置
      backgroundColor: 'transparent',
      title: {
        show: true,
        text: '科技竞赛年度获奖',
        textStyle: {
          color: '#fff',
          fontSize: 20,
        },
        left: 'center',
        top: 0,
      },
      tooltip: {
        show: false,
      },
      legend: {
        show: true,
        data: legends,
        right: 10,
        top: 45,
        textStyle: {
          color: '#fff',
        },
        // selected: legendsSelected,
      },
      toolbox: {
        show: false,
      },
      calculable: true,
      grid: {
        show: false,
        top: 110,
        bottom: 20,
      },
      xAxis: [
        {
          data: xAsisItems,
          type: 'category',
          nameTextStyle: {
            color: '#fff',
          },
          axisLine: {
            //y轴
            show: false,
          },
          axisTick: {
            show: false,
          },
          axisLabel: {
            color: '#ffff',
          },
          splitArea: {
            show: false,
          },
        },
      ],
      yAxis: [
        {
          type: 'value',
          name: '获奖数',
          nameLocation: 'end',
          axisLabel: {
            color: '#ffff',
          },
          axisLine: {
            //y轴
            show: false,
          },
          axisTick: {
            //刻度线
            show: false,
          },
          splitLine: {
            //网格线
            show: true,
            lineStyle: {
              color: 'rgba(255,255,255,.2)',
            },
          },
          splitArea: {
            show: false,
          },
          min(value) {
            return Math.max(value.min - 10, 0);
          },
        },
      ],
      series: data.map((rowData) => {
        return {
          name: rowData.name,
          type: 'line',
          data: rowData.data,
          label: {
            show: true,
            color: '#fff',
            position: 'top',
            formatter: '{c}',
            fontSize: lineLabelFontSize,
          },
          // ...lineEndLabel,
        };
      }),
    };
  }, [data]);

  return <Echarts height={440} option={option} />;
};
ScientificPrices.displayName = 'ScientificPrices';
export default ScientificPrices;
