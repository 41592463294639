import { BorderBox10, BorderBox11 } from '@jiaminghi/data-view-react'
import _ from 'lodash'
import { FC, useMemo } from 'react'
import sum from '../../../utils/sum'
import AnimatedNumber from '../../Animatied/Number'
import AnimatedScrollIntoText from '../../Animatied/ScrollIntoText'
import DLBlock from '../../DLBlock'
import useCarouselData from '../../hooks/useCarouselData'
import { ModuleTitle } from '../../ModuleTitle'
import _NoLeftBorderBox11 from '../../NoLeftBorderBox11'
import Page from '../../Page'
import Campus from './Campus'
import GenderChart from './GenderChart'
import Grade from './Grade'
import styles from './index.module.scss'
import Map from './Map'
import axios from 'axios'
import withRemoteData, {
  replaceGlobalDataWith,
} from '../../../utils/withRemoteData'

interface HomeProps {}
function request() {
  const requests = [
    // axios.get('/api/large/askForLeave').then(({ data: { data } }) => {
    //   window.__data__.indexStudents[0].askForLeave = data.undergardAskForLeave;
    //   window.__data__.indexStudents[1].askForLeave = data.graduateAskForLeave;
    //   window.__data__.indexStudents[2].askForLeave = data.doctorAskForLeave;
    //   window.__data__.campus[0].students -= data.moganshAskForLeave;
    //   window.__data__.campus[1].students -= data.pingfengAskForLeave;
    //   window.__data__.campus[2].students -= data.chaohuiAskForLeave;
    //   return data;
    // }),
    // replaceGlobalDataWith('/api/large/campus', 'campus'),
    replaceGlobalDataWith('/api/large/studentSource', 'studentSource'),
    replaceGlobalDataWith(
      '/api/large/indexStudentsAll',
      'indexStudentsAll'
    ),
  ]
  return Promise.all(requests)
}
const NoLeftBorderBox11: any = _NoLeftBorderBox11
const Home: FC<HomeProps> = () => {
  const { studentSource } = window.__data__
  const allData = window.__data__.indexStudentsAll.students
  console.log(allData)
  const campusData = window.__data__.indexStudentsAll.campus
  const [activeData] = useCarouselData<any>({ data: allData, interval: 5000 })
  const gradesData = useMemo(() => {
    return Array.isArray(activeData.grades)
      ? activeData
      : _.findLast(allData, (row) => Array.isArray(row.grades))
  }, [activeData, allData])
  // const campusTotal = useMemo(() => sum(...campusData.map((d) => d.students)), [campusData]);
  const genderData = useMemo(() => {
    return [
      {
        name: '女生',
        value: activeData.women,
      },
      {
        name: '男生',
        value: activeData.men,
      },
    ]
  }, [activeData])
  const headers = useMemo(() => {
    const totals = {
      students: 0,
      suspension: 0,
      delayedGraduating: 0,
      stayOutSchool: 0,
      askForLeave: 0,
    }
    const descs = {
      students: [],
      suspension: [],
      delayedGraduating: [],
      stayOutSchool: [],
      askForLeave: [],
    }
    allData.forEach((d) => {
      Object.keys(totals).forEach((key) => {
        totals[key] += d[key]
        descs[key].push(`${d.category}: ${d[key]}`)
      })
    })
    return {
      totals,
      descs,
    }
  }, [allData])
  const { totals, descs } = headers
  const campusTotal = useMemo(() => {
    return campusData.reduce(function (prev, curr) {
      return prev.students + curr.students
    })
  }, [totals])
  return (
    <Page title="生情概览">
      <div className={styles.header}>
        <DLBlock
          height={180}
          width={300}
          title="学生总数:"
          titleNumber={totals.students}
          descs={descs.students}
        />
        <DLBlock
          height={180}
          width={300}
          title="休学人数:"
          titleNumber={totals.suspension}
          descs={descs.suspension}
        />
        <DLBlock
          height={180}
          width={300}
          title="延长学制:"
          titleNumber={totals.delayedGraduating}
          descs={descs.delayedGraduating}
        />
        <DLBlock
          height={180}
          width={300}
          title="校外住宿:"
          titleNumber={totals.stayOutSchool}
          descs={descs.stayOutSchool}
        />
        <DLBlock
          height={180}
          width={300}
          title="今日请假:"
          titleNumber={totals.askForLeave}
          descs={descs.askForLeave}
        />
        {/* <div className={styles.headerItem}>
          <div className={styles.headerItemTitle}>
            <AnimatedScrollIntoText value={`今日在校`} height={25} />
          </div>
          <div className={styles.headerItemCount}>
            <AnimatedNumber value={campusTotal} />
            <span>人</span>
          </div>
        </div> */}
      </div>
      <div className={styles.main}>
        <div className={styles.campus}>
          <NoLeftBorderBox11 title="今日在校">
            <Campus data={campusData} total={campusTotal} />
          </NoLeftBorderBox11>
        </div>
        <div className={styles.left}>
          <div className={styles.leftTop}>
            <BorderBox11 title="性别分布">
              <GenderChart data={genderData} category={activeData.category} />
            </BorderBox11>
          </div>
          <div className={styles.leftBottom}>
            <BorderBox11 title="年级分布">
              <Grade data={gradesData.grades} category={gradesData.category} />
            </BorderBox11>
          </div>
        </div>
        <Map data={studentSource} />
      </div>
    </Page>
  )
}
Home.displayName = 'Home'
export default withRemoteData(Home, request)
