import classNames from 'classnames';
import { FC } from 'react';
import styles from './Name.module.scss';

interface DataNameProps {
  className?: string;
  children?: any;
  inline?: boolean;
  isTotal?: boolean;
}
const DataName: FC<DataNameProps> = ({ className, children, inline, isTotal }) => {
  return <div className={classNames(styles.dataName, className, isTotal && styles.total)}>{children}</div>;
};
DataName.displayName = 'DataName';
export default DataName;
