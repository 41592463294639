import { FC } from 'react';
import styles from './index.module.scss';
import { BorderBox13 } from '@jiaminghi/data-view-react';

interface DLBlockProps {
  color?: string;
  height: number;
  title: React.ReactNode;
  descs: React.ReactNode[];
  titleNumber?: number;
  width: number;
  titleFontSize?: number;
  descFontSize?: number;
}
const DLBlock: FC<DLBlockProps> = ({
  height,
  color,
  title,
  titleNumber,
  descs,
  width,
  descFontSize,
  titleFontSize,
}) => {
  return (
    <div className={styles.wrap} style={{ height, color, width }}>
      <BorderBox13>
        <div className={styles.title} style={{ fontSize: titleFontSize }}>
          {title}
          <span className={styles.titleNumber}>{titleNumber}</span>
        </div>
        <div className={styles.descs}>
          {descs.map((text, index) => {
            return (
              <div key={index} className={styles.desc} style={{ fontSize: descFontSize }}>
                {text}
              </div>
            );
          })}
        </div>
      </BorderBox13>
    </div>
  );
};
DLBlock.displayName = 'DLBlock';
DLBlock.defaultProps = {
  color: '#fff',
};
export default DLBlock;
