import { BorderBox11 } from '@jiaminghi/data-view-react'
import { FC } from 'react'
import Page from '../../Page'
import ValueRingRow from '../../ValueRingRow'
import styles from './index.module.scss'
import MonthPopluation from './MonthPopluation'
import GrowPopluation from './GrowPopluation'
import Population from './Population'
import axios from 'axios'
import withRemoteData, {
  replaceGlobalDataWith,
} from '../../../utils/withRemoteData'
import ThreeSpace from './ThreeSpace'
import GradePointRadar from './GradePointRadar'
import { Timeline } from 'antd'
import { Tag } from 'antd'
import { useState } from 'react'
interface StudentPaintProps {}
const monthMap = {
  0: '一月',
  1: '二月',
  2: '三月',
  3: '四月',
  4: '五月',
  5: '六月',
  6: '七月',
  7: '八月',
  8: '九月',
  9: '十月',
  10: '十一月',
  11: '十二月',
}
const rainbowColor = [
  '#ff0000',
  '#ffa500',
  '#ffff00',
  '#00ff00',
  '#007fff',
  '#0000ff',
  '#8b00ff',
]
const typeArrs = ['德育', '智育', '体育', '美育', '劳育', '创新', '先锋']
let imgUrl
const getCaptcha = async (stuNo) => {
  await axios('/api/large/getBytes/' + stuNo, {
    method: 'get',
    responseType: 'blob',
  })
    .then((res) => {
      imgUrl = window.URL.createObjectURL(new Blob([res.data]))
    })
    .catch((err) => {
      console.log(err)
    })
}
function request() {
  let stuIndex = 0
  return Promise.all([
    replaceGlobalDataWith('/api/large/getStuList', 'studentPaint.studentList'),
    replaceGlobalDataWith('/api/large/bkGrow', 'studentPaint.bkGrow'),
    replaceGlobalDataWith('/api/large/yjsGrow', 'studentPaint.yjsGrow'),
    replaceGlobalDataWith(
      '/api/large/bkGrowStatus',
      'studentPaint.bkGrowStatus'
    ),
    replaceGlobalDataWith(
      '/api/large/yjsGrowStatus',
      'studentPaint.yjsGrowStatus'
    ),
  ]).then((res) => {
    return Promise.all([
      getCaptcha(window.__data__.studentPaint.studentList[stuIndex].stuNo),
      replaceGlobalDataWith(
        '/api/large/stuMsgLeft/' +
          window.__data__.studentPaint.studentList[stuIndex].stuNo,
        'studentPaint.stuMsgLeft'
      ),
      replaceGlobalDataWith(
        '/api/large/stuMsgThree/' +
          window.__data__.studentPaint.studentList[stuIndex].stuNo,
        'studentPaint.stuMsgThree'
      ),
      replaceGlobalDataWith(
        '/api/large/gpGrowRadar/' +
          window.__data__.studentPaint.studentList[stuIndex].stuNo,
        'studentPaint.gpRadar'
      ),
      replaceGlobalDataWith(
        '/api/large/sxdt/' +
          window.__data__.studentPaint.studentList[stuIndex].stuNo,
        'studentPaint.sxdt'
      ),
      replaceGlobalDataWith(
        '/api/large/growTimeLine/' +
          window.__data__.studentPaint.studentList[stuIndex].stuNo,
        'studentPaint.growTimeLine'
      ),
      replaceGlobalDataWith(
        '/api/large/growStatus/' +
          window.__data__.studentPaint.studentList[stuIndex].stuNo,
        'studentPaint.growStatus'
      ),
      setInterval(() => {
        if (stuIndex == window.__data__.studentPaint.studentList.length - 1) {
          stuIndex = 0
        } else {
          stuIndex = stuIndex + 1
        }
        replaceGlobalDataWith(
          '/api/large/stuMsgThree/' +
            window.__data__.studentPaint.studentList[stuIndex].stuNo,
          'studentPaint.stuMsgThree'
        )
        replaceGlobalDataWith(
          '/api/large/stuMsgLeft/' +
            window.__data__.studentPaint.studentList[stuIndex].stuNo,
          'studentPaint.stuMsgLeft'
        )
        replaceGlobalDataWith(
          '/api/large/gpGrowRadar/' +
            window.__data__.studentPaint.studentList[stuIndex].stuNo,
          'studentPaint.gpRadar'
        )
        replaceGlobalDataWith(
          '/api/large/sxdt/' +
            window.__data__.studentPaint.studentList[stuIndex].stuNo,
          'studentPaint.sxdt'
        )
        replaceGlobalDataWith(
          '/api/large/growTimeLine/' +
            window.__data__.studentPaint.studentList[stuIndex].stuNo,
          'studentPaint.growTimeLine'
        )
        replaceGlobalDataWith(
          '/api/large/growStatus/' +
            window.__data__.studentPaint.studentList[stuIndex].stuNo,
          'studentPaint.growStatus'
        )
        getCaptcha(window.__data__.studentPaint.studentList[stuIndex].stuNo)
      }, 60000),
    ])
  })
}

let currentPage = 0
function loopData(arr, newLen) {
  let len = arr.length
  let result = len - currentPage
  let newArr = []
  if (result > 0 && result < newLen) {
    newArr = [
      ...arr.slice(currentPage - result, len),
      ...arr.slice(0, newLen - result),
    ]
    currentPage = newLen - result
  } else if (result >= newLen) {
    newArr = arr.slice(currentPage, currentPage + newLen)
    currentPage += newLen
  } else {
    currentPage = 0
    newArr = arr.slice(currentPage, currentPage + newLen)
  }
  return newArr
}
const StudentPaint: FC<StudentPaintProps> = () => {
  let [count, setCount] = useState('')
  let [countSec, setCountSec] = useState('')
  let [growType, setGrowType] = useState('')
  let [grow, setGrow] = useState({ grow1: '', grow2: '' })
  let [growSec, setGrowSec] = useState({ grow1: '', grow2: '' })
  let {
    stuMsgThree,
    stuMsgLeft,
    sxdt,
    gpRadar,
    growStatus,
    bkGrow,
    yjsGrow,
    growTimeLine,
    bkGrowStatus,
    yjsGrowStatus,
  } = window.__data__.studentPaint
  setInterval(growTimeLine.push(growTimeLine.shift()), 3000)

  return (
    <Page title="数字画像">
      <div className={styles.main}>
        <div className={styles.left}>
          <BorderBox11 title="学生信息">
            <div className={styles.lTop}>
              <div className={styles.lLeft}>
                <img src={imgUrl} alt="" onClick={getCaptcha} />
              </div>
              <div className={styles.lRight}>
                {stuMsgThree.map((data) => (
                  <ValueRingRow
                    key={data.name}
                    height={50}
                    title={data.name}
                    marginBottom={22}
                    value={data.value}
                    className={'new-ring'}
                    valueClassName={'new-value'}
                    percentage={null}
                  />
                ))}
              </div>
              <div className={styles.lRight}>
                {stuMsgLeft.map((data) => (
                  <ValueRingRow
                    key={data.name}
                    height={50}
                    marginBottom={22}
                    title={data.name}
                    value={data.value}
                    percentage={null}
                  />
                ))}
              </div>
            </div>
          </BorderBox11>
          <BorderBox11 title="成长动态">
            <div className={styles.lTop}>
              <div className={styles.sxdt}>
                {sxdt.map((data) => (
                  <ValueRingRow
                    key={data.name}
                    height={50}
                    title={data.name}
                    marginBottom={22}
                    value={data.value}
                    className={'new-ring'}
                    valueClassName={'new-value'}
                    percentage={null}
                  />
                ))}
              </div>
            </div>
          </BorderBox11>
        </div>
        <div className={styles.mid}>
          <div className={styles.midBottom}>
            <BorderBox11 title="成长画像">
              <div className={styles.flexBox}>
                <div className={styles.qx}>
                  <ThreeSpace />
                </div>
                <div className={styles.hx}>
                  <GradePointRadar data={gpRadar} />
                </div>
              </div>
            </BorderBox11>
          </div>
        </div>
        <div className={styles.right}>
          <div className={styles.rightBottom}>
            <BorderBox11 title="成长趋势">
              <div className={styles.lBox}>
                {growStatus.map((data) => (
                  <span
                    key={data.type}
                    className={
                      growType == data.type ? styles.bgActive : styles.bg
                    }>
                    {data.type}
                  </span>
                ))}
              </div>
              <MonthPopluation
                msg={growType}
                changeGrowType={(code) => setGrowType(code)}
                data={growStatus}
              />
            </BorderBox11>
          </div>
          <div className={styles.timeLine}>
            <BorderBox11 title="成长轨迹">
              <div className={styles.timeBox}>
                <div className={styles.xBox}>
                  <span className={styles.bg1}></span>德育
                  <span className={styles.bg2}></span>智育
                  <span className={styles.bg3}></span>体育
                  <span className={styles.bg4}></span>美育
                  <span className={styles.bg5}></span>劳育
                  <span className={styles.bg6}></span>创新
                  <span className={styles.bg7}></span>先锋
                </div>
                <Timeline
                  mode="right"
                  items={growTimeLine.map((item) => {
                    return {
                      color: rainbowColor[item.type - 1],
                      children: '0',
                      label: (
                        <Tag color={rainbowColor[item.type - 1]}>
                          {item.date},{item.content}
                        </Tag>
                      ),
                    }
                  })}
                />
              </div>
            </BorderBox11>
          </div>
        </div>
      </div>
      <div className={styles.main}>
        <BorderBox11 title="本科成长指数对比">
          <div className={styles.lBox}>
            {bkGrow
              .map((item) => {
                return (
                  <span
                    className={
                      count == item.year ? styles.bgActive : styles.bg
                    }>
                    {item.year}
                  </span>
                )
              })
              .sort()}
          </div>
          {/* <div className={styles.xBox}>
                <span className={styles.bg1}></span>德育
                <span className={styles.bg2}></span>智育
                <span className={styles.bg3}></span>体育
                <span className={styles.bg4}></span>美育
                <span className={styles.bg5}></span>劳育
                <span className={styles.bg6}></span>创新
                <span className={styles.bg7}></span>先锋
              </div> */}
          <Population
            msg={count}
            data={bkGrow}
            changeCount={(code) => setCount(code)}
          />
        </BorderBox11>
        <BorderBox11 title="本科成长趋势对比">
          <div className={styles.lBox}>
            <span className={styles.bgActive}>{grow.grow2}</span>
          </div>
          <div className={styles.xBox}>
            <span
              className={
                grow.grow1 == '德育' ? styles.bgActive : styles.bg
              }></span>
            德育
            <span
              className={
                grow.grow1 == '智育' ? styles.bgActive : styles.bg
              }></span>
            智育
            <span
              className={
                grow.grow1 == '体育' ? styles.bgActive : styles.bg
              }></span>
            体育
            <span
              className={
                grow.grow1 == '美育' ? styles.bgActive : styles.bg
              }></span>
            美育
            <span
              className={
                grow.grow1 == '劳育' ? styles.bgActive : styles.bg
              }></span>
            劳育
            <span
              className={
                grow.grow1 == '创新' ? styles.bgActive : styles.bg
              }></span>
            创新
            <span
              className={
                grow.grow1 == '先锋' ? styles.bgActive : styles.bg
              }></span>
            先锋
          </div>
          <GrowPopluation
            msg1={grow.grow1}
            msg2={grow.grow2}
            changeGrow={(grow) => setGrow(grow)}
            data={bkGrowStatus}
          />
        </BorderBox11>
        <BorderBox11 title="研究生成长指数对比">
          <div className={styles.lBox}>
            {bkGrow.map((item) => {
              return (
                <span
                  className={
                    countSec == item.year ? styles.bgActive : styles.bg
                  }>
                  {item.year}
                </span>
              )
            })}
          </div>
          <Population
            msg={countSec}
            data={yjsGrow}
            changeCount={(code) => setCountSec(code)}
          />
        </BorderBox11>
        <BorderBox11 title="研究生成长趋势对比">
          <div className={styles.lBox}>
            <span className={styles.bgActive}>{growSec.grow2}</span>
          </div>
          <div className={styles.xBox}>
            <span
              className={
                growSec.grow1 == '德育' ? styles.bgActive : styles.bg
              }></span>
            德育
            <span
              className={
                growSec.grow1 == '智育' ? styles.bgActive : styles.bg
              }></span>
            智育
            <span
              className={
                growSec.grow1 == '体育' ? styles.bgActive : styles.bg
              }></span>
            体育
            <span
              className={
                growSec.grow1 == '美育' ? styles.bgActive : styles.bg
              }></span>
            美育
            <span
              className={
                growSec.grow1 == '劳育' ? styles.bgActive : styles.bg
              }></span>
            劳育
            <span
              className={
                growSec.grow1 == '创新' ? styles.bgActive : styles.bg
              }></span>
            创新
            <span
              className={
                growSec.grow1 == '先锋' ? styles.bgActive : styles.bg
              }></span>
            先锋
          </div>
          <GrowPopluation
            msg1={growSec.grow1}
            msg2={growSec.grow2}
            changeGrow={(growSec) => {
              setGrowSec(growSec)
            }}
            data={yjsGrowStatus}
          />
        </BorderBox11>
      </div>
    </Page>
  )
}
StudentPaint.displayName = 'StudentPaint'
export default withRemoteData(StudentPaint, request)
