import { FC, useMemo } from 'react';
import { barLabelFontSize, barRadius, barWidth } from '../../../utils/var';
import Echarts from '../../ECharts';
import colorPalette from '../../ECharts/themes/colorPalette';

interface FailTestProps {
  data: any;
}

const FailTest: FC<FailTestProps> = ({ data }) => {
  const option = useMemo(() => {
    const xAxisItems = data[0].grades.map((g) => g.name);

    return {
      //画布背景色设置
      backgroundColor: 'transparent',
      title: {
        show: true,
        text: '各年级不及格人数',
        left: 'center',
        top: '0',
        textStyle: {
          fontSize: 16,
          color: '#fff',
        },
      },
      tooltip: {
        show: false,
      },
      legend: {
        show: true,
        data: data.map((d) => d.name),
        right: 10,
        top: 40,
        textStyle: {
          color: '#fff',
        },
      },
      toolbox: {
        show: false,
      },
      calculable: true,
      grid: {
        show: false,
        top: 100,
        bottom: 40,
      },
      xAxis: [
        {
          type: 'category',
          data: xAxisItems,
          nameTextStyle: {
            color: '#fff',
          },
          axisLine: {
            //y轴
            show: false,
          },
          axisTick: {
            show: false,
          },
          axisLabel: {
            color: '#ffff',
          },
          splitArea: {
            show: false,
          },
        },
      ],
      yAxis: [
        {
          name: '人数',
          minInterval: 1,
          type: 'value',
          axisLine: {
            //y轴
            show: false,
          },
          axisTick: {
            //刻度线
            show: false,
          },
          splitLine: {
            //网格线
            show: false,
          },
          splitArea: {
            show: false,
          },
          axisLabel: {
            textStyle: {
              color: '#fff',
              fontSize: 12,
              padding: [0, 10, 0, 0],
            },
          },
        },
      ],
      series: data.map((warning, index) => {
        return {
          name: warning.name,
          type: 'bar',
          barWidth,
          label: {
            show: true,
            color: '#fff',
            position: 'top',
            formatter: '{c}',
            fontSize: barLabelFontSize,
          },
          data: warning.grades,
          barGap: '30%',
          itemStyle: {
            //柱形图圆角，顺时针左上，右上，右下，左下）
            barBorderRadius: barRadius,
          },
        };
      }),
    };
  }, [data]);
  return <Echarts height={280} option={option} />;
};
FailTest.displayName = 'FailTest';
export default FailTest;
