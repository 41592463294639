import { BorderBox11 } from '@jiaminghi/data-view-react';
import { FC } from 'react';
import Page from '../../Page';
import ControlMonthlyChange from './ControlMonthlyChange';
import Data from './Data';
import styles from './index.module.scss';
import Map from './Map';
import OutZhejiangWeeklyChange from './OutZhejiangWeeklyChange';
import axios from 'axios';
import withRemoteData, { replaceGlobalDataWith } from '../../../utils/withRemoteData';

interface COVID19Props {}

const monthMap = {
  0: '一月',
  1: '二月',
  2: '三月',
  3: '四月',
  4: '五月',
  5: '六月',
  6: '七月',
  7: '八月',
  8: '九月',
  9: '十月',
  10: '十一月',
  11: '十二月',
};
function request() {
  // return axios.get('/api/large/trip').then(({ data: { data } }) => {
  //   window.__data__.covid19.leftTop[0].value = data.highRisk + '人';
  //   window.__data__.covid19.leftTop[1].value = data.midRisk + '人';
  //   window.__data__.covid19.leftTop[2][0].value = data.thisWeekLeaveHanZhou + '人';
  //   window.__data__.covid19.leftTop[2][1].value = data.thisWeekLeaveProvince + '人';
  //   window.__data__.covid19.leftTop[3].value = data.leaveSchool + '人';
  //   return data;
  // });
  return Promise.all([
    replaceGlobalDataWith('/api/large/covid19LeftTop', 'covid19.leftTop'),
    replaceGlobalDataWith('/api/large/covid19rightTop', 'covid19.rightTop'),
    replaceGlobalDataWith('/api/large/covid19OutZhejiangWeeklyChange', 'covid19.outZhejiangWeeklyChange'),
    replaceGlobalDataWith('/api/large/covid19OutZhejiangList', 'covid19.outZhejiangList'),
    replaceGlobalDataWith('/api/large/controlMonthlyChange', 'covid19.controlMonthlyChange'),
  ]);
}
const COVID19: FC<COVID19Props> = () => {
  const { outZhejiangWeeklyChange, controlMonthlyChange, outZhejiangList } = window.__data__.covid19;
  return (
    <Page title="常态化疫情防控">
      <div className={styles.main}>
        <div className={styles.left}>
          <div className={styles.leftTop}>
            <Data data={window.__data__.covid19.leftTop} />
          </div>
          <div className={styles.leftBottom}>
            <BorderBox11 title="出杭出省周度变化">
              <OutZhejiangWeeklyChange data={outZhejiangWeeklyChange.series} />
            </BorderBox11>
          </div>
        </div>
        <Map data={outZhejiangList} />
        <div className={styles.right}>
          <div className={styles.rightTop}>
            <Data data={window.__data__.covid19.rightTop} />
          </div>
          <div className={styles.rightBottom}>
            <BorderBox11 title="管控人数月度变化">
              <ControlMonthlyChange data={controlMonthlyChange} />
            </BorderBox11>
          </div>
        </div>
      </div>
    </Page>
  );
};
COVID19.displayName = 'COVID19';
export default withRemoteData(COVID19, request);
