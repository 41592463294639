import './App.css';
import './components/WebComponents';
import Home from './components/pages/Home';
import { HashRouter, Routes, Route } from 'react-router-dom';
import FiveLove from './components/pages/FiveLove';
import COVID19 from './components/pages/COVID19';
import WeiLaiSheQu from './components/pages/weilaixiaoqu';
import AcademicSentiment from './components/pages/AcademicSentiment';
import Innovate from './components/pages/Innovate';
import PartyBuilding from './components/pages/PartyBuilding';
import StudentPaint from './components/pages/StudentPaint';
function App() {
  return (
    <HashRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/five-love" element={<FiveLove />} />
        <Route path="/covid-19" element={<COVID19 />} />
        <Route path="/weilaixiaoqu" element={<WeiLaiSheQu />} />
        <Route path="/academic-sentiment" element={<AcademicSentiment />}></Route>
        <Route path="/innovate" element={<Innovate />}></Route>
        <Route path="/party-building" element={<PartyBuilding />}></Route>
        <Route path="/student-paint" element={<StudentPaint />}></Route>
      </Routes>
    </HashRouter>
  );
}

export default App;
