import { useEffect, useState } from 'react';

function useCarouselData<T>({
  data,
  interval,
  infinity = true,
  onEnd,
}: {
  data: T[];
  interval: number | ((data: T) => number);
  infinity?: boolean;
  onEnd?: () => void;
}) {
  const [active, setActive] = useState(0);
  useEffect(() => {
    let timer;
    if (active !== 0) {
      setActive(0);
    }
    if (!Array.isArray(data)) {
      return;
    }
    const startTimer = (fn: any, data: any) => {
      clearTimeout(timer);
      timer = setTimeout(
        () => {
          fn();
        },
        typeof interval === 'function' ? interval(data) : interval,
      );
    };
    const goNext = () => {
      setActive((prev) => {
        const next = prev + 1;
        //end
        if (next >= data.length) {
          if (infinity) {
            startTimer(goNext, data[0]);
            return 0;
          }
          clearTimeout(timer);
          onEnd?.();
          return prev;
        }
        startTimer(goNext, data[next]);
        return next;
      });
    };
    startTimer(goNext, data[0]);
    return () => {
      clearTimeout(timer);
    };
  }, [data]);
  const activeData = Array.isArray(data) ? data[active] : null;
  return [activeData, active, setActive] as [T, number, (next: number) => void];
}
export default useCarouselData;
