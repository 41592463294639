import { FC, useMemo } from 'react';
import { barLabelFontSize, barRadius, barWidth } from '../../../utils/var';
import Echarts from '../../ECharts';
import colorPalette from '../../ECharts/themes/colorPalette';

interface ScientificResearchProps {
  data: any;
}
const ScientificResearch: FC<ScientificResearchProps> = ({ data }) => {
  const option = useMemo(() => {
    const xAxisItems = data.map((d) => d.name);

    return {
      //画布背景色设置
      backgroundColor: 'transparent',
      title: {
        show: false,
      },
      tooltip: {
        show: false,
      },
      legend: {
        show: false,
      },
      toolbox: {
        show: false,
      },
      calculable: true,
      grid: {
        show: false,
        left: 70,
        right: 20,
      },
      xAxis: [
        {
          type: 'category',
          data: xAxisItems,
          nameTextStyle: {
            color: '#fff',
          },
          axisLine: {
            //y轴
            show: false,
          },
          axisTick: {
            show: false,
          },
          axisLabel: {
            color: '#ffff',
          },
          splitArea: {
            show: false,
          },
        },
      ],
      yAxis: [
        {
          name: '人数',
          minInterval: 1,
          type: 'value',
          axisLine: {
            //y轴
            show: false,
          },
          axisTick: {
            //刻度线
            show: false,
          },
          splitLine: {
            //网格线
            show: false,
          },
          splitArea: {
            show: false,
          },
          axisLabel: {
            textStyle: {
              color: '#fff',
              fontSize: 12,
              padding: [0, 10, 0, 0],
            },
          },
        },
      ],
      series: [
        {
          name: '年级人数',
          type: 'bar',
          //设置柱状图宽度
          barWidth,
          label: {
            show: true,
            color: '#fff',
            position: 'top',
            formatter: '{c}',
            fontSize: barLabelFontSize,
          },
          data,
          itemStyle: {
            //柱形图圆角，顺时针左上，右上，右下，左下）
            barBorderRadius: barRadius,
            color(param: any) {
              return colorPalette[param.dataIndex];
            },
            //设置柱状图颜色渐变
          },
        },
      ],
    };
  }, [data]);
  return <Echarts height={420} option={option} />;
};
ScientificResearch.displayName = 'ScientificResearch';
export default ScientificResearch;
