import { FC, useMemo } from 'react';
import { ScrollBoard } from '@jiaminghi/data-view-react';
interface DormsProps {
  data: any[];
  height?: number;
  fullpage?: boolean;
  noIndex?: boolean;
}
const Dorms: FC<DormsProps> = ({ data, height, fullpage, noIndex }) => {
  const config = useMemo(() => {
    return {
      // 表头背景色
      headerBGC: '#443dc5',
      // 奇数行背景色
      oddRowBGC: '#09184F',
      // 偶数行背景色
      evenRowBGC: '#070C34',
      // 行号
      index: !noIndex,
      // 行号表头
      indexHeader: '序号',
      // 宽度
      columnWidth: noIndex ? [88, 88, 88, 88, 88, 88,88,88] : [60, 80, 80, 80, 80, 80,80,80],
      // 对其方式
      align: ['center', 'center', 'center', 'center', 'center', 'center', 'center', 'center'],
      // 表行数
      rowNum: 10,
      header: ['寝室号','和谐指数','书香指数', '先锋指数', '学风指数', '安全指数', '创新指数', '劳动指数'],
      data: data.map((dorm) => {
        return dorm.map((d, index) => {
          if (index === 0) {
            return d;
          }
          if (typeof d !== 'number') {
            return d;
          }
          return `<x-star rating="${d}" max="${Math.ceil(d)}" dimension="12px" spacing="0px" />`;
        });
      }),
      waitTime: fullpage ? 5000 : 1200,
      carousel: fullpage ? 'page' : 'single',
    };
  }, [data]);
  return (
    <ScrollBoard
      config={config}
      style={{
        width: '700px',
        height: height ?? '265px',
        margin: '0 auto',
      }}
    />
  );
};
Dorms.displayName = 'Dorms';
export default Dorms;
