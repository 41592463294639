import { useTransition, config, animated } from '@react-spring/web';
import { CSSProperties, FC, useMemo } from 'react';

interface AnimatedScrollIntoTextProps {
  value: any;
  height: number;
  wrapClassName?: string;
  className?: string;
  wrapStyle?: CSSProperties;
  style?: CSSProperties;
  width?: number;
  block?: boolean;
}
const styles = {
  block: {},
  inline: {
    display: 'inline-block',
    verticalAlign: 'middle',
  },
};
const AnimatedScrollIntoText: FC<AnimatedScrollIntoTextProps> = ({
  height,
  width,
  wrapClassName,
  className,
  value,
  block,
  wrapStyle: propWrapStyle,
  style,
}) => {
  const items = useMemo(() => {
    return [value];
  }, [value]);
  const transitions = useTransition(items, {
    from: { x: 1 },
    enter: { x: 0 },
    leave: { x: -1 },
    config: config.molasses,
  });
  const defaultStyle = block ? styles.block : styles.inline;
  const wrapStyle: CSSProperties = {
    ...defaultStyle,
    ...propWrapStyle,
    position: 'relative',
    overflow: 'hidden',
    height,
  };
  if (width) {
    wrapStyle.width = width;
  }
  return (
    <div className={wrapClassName} style={wrapStyle}>
      {transitions(({ x }, item) => {
        return (
          <animated.div
            className={className}
            style={{
              ...style,
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              transform: x.to((y) => `translate(0, ${(y * 100).toFixed(0)}%)`),
            }}
          >
            {item}
          </animated.div>
        );
      })}
    </div>
  );
};
AnimatedScrollIntoText.displayName = 'AnimatedScrollIntoText';
AnimatedScrollIntoText.defaultProps = {
  block: true,
};
export default AnimatedScrollIntoText;
