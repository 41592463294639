import echarts from 'echarts/lib/echarts';
import _ from 'lodash';
import { findProvinceCord, provinceRegionMap } from '../../../ECharts/Maps/chinaCords';

const lineColors = {
  safe: {
    type: 'linear',
    x: 0,
    y: 0,
    x2: 0,
    y2: 1,
    colorStops: [
      {
        offset: 0,
        color: 'cyan',
      },
      {
        offset: 1,
        color: '#fff',
      },
    ],
    global: false,
  },
  danger: {
    type: 'linear',
    x: 0,
    y: 0,
    x2: 0,
    y2: 1,
    colorStops: [
      {
        offset: 0,
        color: '#F58158',
      },
      {
        offset: 1,
        color: '#934f91',
      },
    ],
    global: false,
  },
};
const pointColors = {
  safe: '#58B3CC',
  danger: '#F58158',
};
const highlightStyle = {
  borderColor: '#FFD1A3',
  areaColor: 'rgba(102,105,240,.3)',
  borderWidth: 1,
  shadowBlur: 5,
  shadowColor: 'rgba(135,138,255,.5)',
};
//hong F58158 lan 58B3CC
export const mapOptions = (sortedData) => {
  const HandZhouCoords = findProvinceCord('浙江');
  const lines = [];
  const regions = [];
  const points = sortedData.map((province, index) => {
    const isGoDanger = province.danger;
    const colorKey = isGoDanger ? 'danger' : 'safe';
    lines.push({
      fromName: '杭州',
      toName: province.name,
      coords: [HandZhouCoords, findProvinceCord(province.name)],
      lineStyle: {
        color: lineColors[colorKey],
      },
    });
    regions.push({
      name: provinceRegionMap[province.name],
      itemStyle: highlightStyle,
    });
    return {
      name: province.name,
      value: findProvinceCord(province.name),
      symbolSize: 2,
      itemStyle: {
        color: pointColors[colorKey],
      },
    };
  });
  return {
    title: {
      show: true,
      text: '最近一周出省去向',
      left: '390px',
      top: '0',
      textStyle: {
        fontSize: 24,
        color: '#fff',
      },
    },
    legend: {
      show: false,
    },
    geo: {
      regions,
      nameMap: {
        china: '中国',
      },
      map: 'china',
      label: {
        emphasis: {
          show: false,
        },
      },
      zoom: 1.2,
      itemStyle: {
        normal: {
          borderColor: 'rgba(255,209,163, .5)', //区域边框颜色
          areaColor: 'rgba(73,86,166,.1)', //区域颜色
          borderWidth: 0.5, //区域边框宽度
          shadowBlur: 5,
          shadowColor: 'rgba(107,91,237,.7)',
        },
        emphasis: highlightStyle,
      },
    },
    series: [
      {
        name: '地点',
        type: 'effectScatter',
        coordinateSystem: 'geo',
        zlevel: 2,
        rippleEffect: {
          brushType: 'stroke',
        },
        label: {
          show: true,
          formatter: '{b}',
          position: 'right',
          color: '#fff',
        },
        symbolSize: 2,
        showEffectOn: 'render',
        // itemStyle: {
        //   color: '#46bee9',
        // },
        data: points,
      },
      {
        name: '线路',
        type: 'lines',
        coordinateSystem: 'geo',
        zlevel: 2,
        zoom: 1,
        large: true,
        effect: {
          show: true,
          constantSpeed: 30,
          symbol: 'pin',
          symbolSize: 3,
          trailLength: 0,
        },
        lineStyle: {
          width: 2,
          opacity: 0.4,
          curveness: 0.1,
        },
        data: lines,
      },
    ],
  };
};
