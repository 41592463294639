import { FC, useMemo } from 'react';
import Echarts from '../../ECharts';
import { academicSentimentData } from './data';

interface TruancyProps {
  data: any;
}
const Truancy: FC<TruancyProps> = ({ data }) => {
  const option = useMemo(() => {
    const xAsisItems = data.week.days.map((d) => d.name);
    return {
      //画布背景色设置
      backgroundColor: 'transparent',
      title: {
        show: true,
        text: `本周旷课情况`,
        // subtext: `旷课总数${data.week.total}人`,
        subtextStyle: {
          color: '#fff',
        },
        left: 'center',
        top: '0',
        textStyle: {
          fontSize: 16,
          color: '#fff',
        },
      },
      tooltip: {
        show: false,
      },
      legend: {
        show: false,
      },
      toolbox: {
        show: false,
      },
      calculable: true,
      grid: {
        show: false,
        bottom: 30,
      },
      xAxis: [
        {
          data: xAsisItems,
          type: 'category',
          nameTextStyle: {
            color: '#fff',
          },
          axisLine: {
            //y轴
            show: false,
          },
          axisTick: {
            show: false,
          },
          axisLabel: {
            color: '#ffff',
          },
          splitArea: {
            show: false,
          },
        },
      ],
      yAxis: [
        {
          type: 'value',
          name: '人数',
          minInterval: 1,
          nameLocation: 'end',
          axisLabel: {
            color: '#ffff',
          },
          axisLine: {
            //y轴
            show: false,
          },
          axisTick: {
            //刻度线
            show: false,
          },
          splitLine: {
            //网格线
            show: true,
            lineStyle: {
              color: 'rgba(255,255,255,.2)',
            },
          },
          splitArea: {
            show: false,
          },
          max(value) {
            return Math.max(value.max, 5);
          },
          min(value) {
            return Math.max(value.min - 10, 0);
          },
        },
      ],
      series: [
        {
          name: '旷课人数',
          type: 'line',
          data: data.week.days,
          label: {
            show: true,
            color: '#fff',
            position: 'top',
            formatter: '{c}',
          },
        },
      ],
    };
  }, [data]);
  return <Echarts height={220} option={option} />;
};
Truancy.displayName = 'Truancy';
export default Truancy;
