import { FC, useMemo } from 'react';
import { ScrollBoard } from '@jiaminghi/data-view-react';

interface THProps {
  data: any;
}
const TH: FC<THProps> = ({ data }) => {
  const config = useMemo(() => {
    return {
      // 表头背景色
      headerBGC: '#443dc5',
      // 奇数行背景色
      oddRowBGC: '#09184F',
      // 偶数行背景色
      evenRowBGC: '#070C34',
      // 行号
      index: false,
      // 行号表头
      indexHeader: '序号',
      // 宽度
      columnWidth: [100, 110, 180, 100],
      // 对其方式
      align: ['center', 'center', 'center', 'center'],
      // 表行数
      rowNum: 10,
      header: ['期数', '时间', '主题', '主讲人'],
      data,
      // carousel: 'page',
    };
  }, [data]);
  return (
    <ScrollBoard
      config={config}
      style={{
        width: '490px',
        height: '210px',
        margin: '0 auto',
      }}
    />
  );
};
TH.displayName = 'TH';
export default TH;
