import { FC, useMemo } from 'react';
import Echarts from '../../ECharts';
import useCarouselData from '../../hooks/useCarouselData';

interface CETProps {
  data: any;
}
const CET: FC<CETProps> = ({ data }) => {
  const [cet, active] = useCarouselData<any>({ data: data.cet, interval: 8000 });
  const option = useMemo(() => {
    let parsedData = {
      name: [],
      value: [],
      totalValue: [],
    };
    cet.grades.forEach((item) => {
      parsedData.name.unshift(`${item.name}`);
      parsedData.value.unshift(item.value);
      parsedData.totalValue.unshift(data.total);
    });
    return {
      background: 'transparent',
      // tooltip: {
      //   trigger: 'axis'
      // },
      title: {
        show: true,
        text: cet.name,
        subtext: `${cet.short}通过总数 ${cet.passedCount}人`,
        left: 'center',
        top: 0,
        textStyle: {
          fontSize: 24,
          color: '#fff',
        },
        subtextStyle: {
          color: '#fff',
        },
      },
      grid: {
        left: 100,
        right: 90,
        top: 60,
        bottom: 0,
        containLabel: false,
      },
      xAxis: {
        type: 'value',
        // boundaryGap: [0, 0.01],
        splitLine: {
          //去除网格线
          show: false,
        },
        axisLine: {
          //坐标轴线条相关设置(颜色等)
          show: false,
        },
        splitArea: {
          show: false,
        },
        axisLabel: {
          show: false,
        },
      },
      yAxis: {
        name: '',
        type: 'category',
        // nameTextStyle :{
        //   fontSize: 12,
        //   color: '#ffffff',
        //   padding: [0,0,0,60]
        // },
        axisLine: {
          //坐标轴线条相关设置(颜色等)
          show: false,
          // lineStyle:{
          //   color:'#ffffff',
          // }
        },
        axisTick: {
          //不显示刻度线
          show: false,
        },
        axisLabel: {
          textStyle: {
            color: '#fff',
            fontSize: 12,
            padding: [0, 10, 0, 0],
          },
        },
        data: parsedData.name,
      },
      series: [
        {
          type: 'bar',
          itemStyle: {
            //---图形形状
            normal: {
              color: {
                type: 'linear',
                x: 0,
                y: 0,
                x2: 1,
                y2: 0,
                colorStops: [
                  {
                    offset: 0,
                    color: '#34ECFF',
                  },
                  {
                    offset: 1,
                    color: '#FFE18F',
                  },
                ],
                global: false,
              },
            },
          },
          barWidth: 15, //---柱形宽度
          barCategoryGap: 14, //---柱形间距
          data: parsedData.value,
          z: 1,
          zlevel: 2,
        },
        {
          type: 'bar',
          barWidth: 15,
          barGap: '-100%',
          data: parsedData.totalValue.map(function (item, index) {
            return {
              value: item,
              realValue: parsedData.value[index],
            };
          }),
          label: {
            show: true,
            position: 'right',
            formatter: function (params) {
              return params.data.realValue + '%';
            },
            color: '#EDEF13',
            fontSize: 14,
            padding: [0, 0, 0, 7],
          },
          itemStyle: {
            color: '#69739C',
          },
          z: 0,
          zlevel: 1,
        },
        {
          // 分隔
          type: 'pictorialBar',
          itemStyle: {
            normal: {
              color: '#0C2161',
            },
          },
          symbol: 'rect',
          symbolRepeat: 'fixed',
          symbolMargin: 2,
          symbolClip: true,
          symbolSize: [5, 18],
          // symbolBoundingData: 100,
          data: parsedData.totalValue,
          width: 2,
          z: 10,
          zlevel: 3,
        },
      ],
    };
  }, [cet.grades, cet.name, cet.short, cet.passedCount, data.total]);
  return <Echarts height={240} option={option} />;
};
CET.displayName = 'CET';
export default CET;
