import { FC, useMemo } from 'react';
import { barWidth } from '../../../utils/var';
import Echarts from '../../ECharts';
import colorPalette from '../../ECharts/themes/colorPalette';
import { academicSentimentData } from './data';

interface CoursesProps {
  data: any;
}
const Courses: FC<CoursesProps> = ({ data }) => {
  const option = useMemo(() => {
    const xAxisItems = data[0].courses.map((g) => g.name);
    return {
      //画布背景色设置
      backgroundColor: 'transparent',
      title: {
        show: false,
        // text: '各年级不及格人数',
        // left: 'center',
        // top: '0',
        // textStyle: {
        //   fontSize: 16,
        //   color: '#fff',
        // },
      },
      tooltip: {
        show: false,
      },
      legend: {
        show: true,
        data: data.map((d) => d.name),
        left: 'center',
        top: 0,
        textStyle: {
          color: '#fff',
          fontSize: 12,
        },
      },
      toolbox: {
        show: false,
      },
      calculable: true,
      grid: {
        show: false,
        top: 80,
        bottom: 20,
      },
      xAxis: [
        {
          type: 'category',
          data: xAxisItems,
          nameTextStyle: {
            color: '#fff',
          },
          axisLine: {
            //y轴
            show: false,
          },
          axisTick: {
            show: false,
          },
          axisLabel: {
            color: '#ffff',
          },
          splitArea: {
            show: false,
          },
        },
      ],
      yAxis: [
        {
          name: '人数',
          minInterval: 1,
          type: 'value',
          axisLine: {
            //y轴
            show: false,
          },
          axisTick: {
            //刻度线
            show: false,
          },
          splitLine: {
            //网格线
            show: false,
          },
          splitArea: {
            show: false,
          },
          axisLabel: {
            textStyle: {
              color: '#fff',
              fontSize: 12,
              padding: [0, 10, 0, 0],
            },
          },
        },
      ],
      series: data.map((item, index) => {
        return {
          name: item.name,
          type: 'bar',
          barWidth: 10,
          label: {
            show: true,
            color: '#fff',
            position: 'top',
            formatter: '{c}',
            fontSize: 12,
          },
          data: item.courses,
          barGap: '75%',
          itemStyle: {
            //柱形图圆角，顺时针左上，右上，右下，左下）
            barBorderRadius: [2, 2, 2, 2],
          },
        };
      }),
    };
  }, [data]);
  return <Echarts height={220} option={option} />;
};
Courses.displayName = 'Courses';
export default Courses;
