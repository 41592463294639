import { FC, useMemo } from 'react';
import Echarts from '../../ECharts';

interface WaringGradesProps {
  data: any;
}
const WaringGrades: FC<WaringGradesProps> = ({ data }) => {
  const option = useMemo(() => {
    const xAxisItems = data[0].grades.map((g) => g.name);

    return {
      //画布背景色设置
      backgroundColor: 'transparent',
      title: {
        show: true,
        text: '年级分布',
        left: 'center',
        top: '32px',
        textStyle: {
          fontSize: 24,
          color: '#fff',
        },
      },
      tooltip: {
        show: false,
      },
      legend: {
        show: true,
        data: data.map((d) => d.name),
        right: 10,
        textStyle: {
          color: '#fff',
        },
      },
      toolbox: {
        show: false,
      },
      calculable: true,
      grid: {
        show: false,
        top: 100,
        bottom: 40,
      },
      xAxis: [
        {
          type: 'category',
          data: xAxisItems,
          nameTextStyle: {
            color: '#fff',
          },
          axisLine: {
            //y轴
            show: false,
          },
          axisTick: {
            show: false,
          },
          axisLabel: {
            color: '#ffff',
          },
          splitArea: {
            show: false,
          },
        },
      ],
      yAxis: [
        {
          name: '人数/人',
          type: 'value',
          axisLine: {
            //y轴
            show: false,
          },
          axisTick: {
            //刻度线
            show: false,
          },
          splitLine: {
            //网格线
            show: false,
          },
          splitArea: {
            show: false,
          },
          axisLabel: {
            textStyle: {
              color: '#fff',
              fontSize: 12,
              padding: [0, 10, 0, 0],
            },
          },
        },
      ],
      series: data.map((warning) => {
        return {
          name: warning.name,
          type: 'bar',
          barWidth: '12',
          label: {
            show: true,
            color: '#fff',
            position: 'top',
            formatter: '{c}',
          },
          data: warning.grades,
          itemStyle: {
            //柱形图圆角，顺时针左上，右上，右下，左下）
            barBorderRadius: [8, 8, 8, 8],
            color: warning.color,
            //设置柱状图颜色渐变
            // color(param: any) {
            //   return colors[param.dataIndex + 1] || colorPalette[param.dataIndex];
            // },
          },
        };
      }),
    };
  }, [data]);
  return <Echarts height={420} option={option} />;
};
WaringGrades.displayName = 'WaringGrades';
export default WaringGrades;
