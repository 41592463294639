import classNames from 'classnames';
import { FC } from 'react';
import styles from './Value.module.scss';

interface DataValueProps {
  className?: string;
  children?: any;
  inline?: boolean;
  isTotal?: boolean;
}
const DataValue: FC<DataValueProps> = ({ className, children, isTotal }) => {
  return <div className={classNames(styles.dataValue, className, isTotal && styles.total)}>{children}</div>;
};
DataValue.displayName = 'DataValue';
export default DataValue;
