import { FC, useMemo } from 'react';
import Echarts from '../../../ECharts';
import { mapOptions } from './options';
import { ScrollBoard } from '@jiaminghi/data-view-react';
import { BorderBox3 } from '@jiaminghi/data-view-react';

interface MapProps {
  data: any;
}
const Map: FC<MapProps> = ({ data }) => {
  const sortedData = useMemo(
    () =>
      data.sort((a, b) => {
        const pa = a.danger ? 1 : 0;
        const pb = b.danger ? 1 : 0;
        if (pa === pb) {
          return b.count - a.count;
        }
        return pb - pa;
      }),
    [data],
  );
  const options = useMemo(() => {
    return mapOptions(sortedData);
  }, [sortedData]);
  const config = useMemo(() => {
    return {
      // 表头背景色
      headerBGC: '#443dc5',
      // 奇数行背景色
      oddRowBGC: '#09184F',
      // 偶数行背景色
      evenRowBGC: '#070C34',
      // 行号
      index: true,
      // 行号表头
      indexHeader: '序号',
      // 宽度
      columnWidth: [70, 120],
      // 对其方式
      align: ['center', 'center'],
      // 表行数
      rowNum: 10,
      header: ['去往省份', '人次'],
      data: sortedData.map(({ name, count, danger }) => [danger ? `${name}*` : name, count]),
    };
  }, [sortedData]);
  return (
    <div
      style={{
        width: 1100,
        position: 'relative',
        paddingTop: 34,
        height: 924,
      }}
    >
      <Echarts option={options} height={840} />
      <ScrollBoard
        config={config}
        style={{
          width: '290px',
          height: '405px',
          position: 'absolute',
          left: '34px',
          bottom: '0px',
        }}
      />
    </div>
  );
};
Map.displayName = 'Map';
export default Map;
