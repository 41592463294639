import { FC, useMemo } from 'react'
import Echarts from '../../ECharts'
import useCarouselData from '../../hooks/useCarouselData'

interface GradePointRadarProps {
  data: any
}

const GradePointRadar: FC<GradePointRadarProps> = ({ data }) => {
  const max = useMemo(() => {
    let res: number = 0
    data.forEach((d) => {
      d.value.forEach((v) => {
        res = Math.max(res, v)
      })
    })
    return res
  }, [data])
  console.log(data)
  const options = useMemo(() => {
    const legendsSelected: any = {}
    // const legends = data.map((d) => {
    //   legendsSelected[d.name] = d.name === activeData.name
    //   return d.name
    // })
    return {
      title: {
        text: '',
        left: 'center',
        textStyle: {
          color: '#fff',
          fontSize: 16,
        },
      },
      grid: {
        top: 300,
      },
      legend: {
        data: ['本人', '专业平均'],
        textStyle: {
          color: '#fff',
        },

        bottom: 0,
        left: 'center',
        itemGap: 20,
      },
      color: ['#0cb6ff', '#ff0000'],
      radar: {
        // shape: 'circle',
        radius: '75%',
        center: ['50%', '50%'],
        indicator: [
          { name: '德育', max },
          { name: '智育', max },
          { name: '体育', max },
          { name: '美育', max },
          { name: '劳育', max },
          { name: '创新', max },
          { name: '先锋', max },
        ],
        shape: 'circle',
        splitNumber: 5,
        axisName: {
          color: 'rgb(238, 197, 102)',
        },
        splitLine: {
          lineStyle: {
            color: [
              'rgba(238, 197, 102, 0.1)',
              'rgba(238, 197, 102, 0.2)',
              'rgba(238, 197, 102, 0.4)',
              'rgba(238, 197, 102, 0.6)',
              'rgba(238, 197, 102, 0.8)',
              'rgba(238, 197, 102, 1)',
            ].reverse(),
          },
        },
        splitArea: {
          show: false,
        },
        axisLine: {
          lineStyle: {
            color: 'rgba(238, 197, 102, 0.5)',
          },
        },
      },
      series: [
        {
          name: '',
          type: 'radar',
          data: [
            {
              value: data[0].value,
              name: '本人',
            },
            {
              value: data[1].value,
              name: '专业平均',
              lineStyle: {
                color: '#ff0000',
              },
              symbolColor: '#ff0000',
            },
          ],
          // data.map((d, index) => {
          //   return {
          //     name: ['0-1', '1-2', '2-3', '3-4', '4-5'][index],
          //     value: d,
          //     label: {
          //       position: 'right',
          //     },
          //   };
          // }),
          label: {
            show: true,
            // position: 'top',
            // distance: 5,
            color: '#fff',
            formatter: '{c}',
            // formatter: (params) => {
            //   return `{${params % 2 ? 'left' : 'right'}|${params.value}}%`;
            // },
            // rich: {
            //   left: {
            //     align: 'left',
            //     width: '300px',
            //   },
            //   right: {
            //     align: 'right',
            //     width: '300px',
            //   },
            // },
          },
          labelLayout(params) {
            // const v = parseFloat(params.text)
            // const index = activeData.value.findIndex((a) => a === v)
            // if (index === -1) {
            //   return {}
            // }
            // return {
            //   dx: index % 2 ? -20 : 20,
            // }
          },
          areaStyle: {
            opacity: 0.1,
          },
        },
      ],
    }
  }, [data, max])
  return (
    <div style={{ marginTop: 20, width: 470 }}>
      <Echarts height={450} option={options} />
    </div>
  )
}
GradePointRadar.displayName = 'GradePointRadar'
export default GradePointRadar
