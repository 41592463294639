import * as echarts from 'echarts';

echarts.registerMap('china', { geoJSON: window.geoData.china });
echarts.registerMap('zhejiang', { geoJSON: window.geoData.zhejiang });
echarts.registerMap('campusCitys', { geoJSON: window.geoData.campusCitys });
// echarts.registerMap('xq1', {
//   geoJSON: {
//     type: 'FeatureCollection',
//     features: [
//       {
//         type: 'Feature',
//         id: 6765,
//         bbox: [120.16172812, 30.29313050999998, 120.16822524000001, 30.296452849999998],
//         properties: {},
//         geometry: {
//           type: 'Polygon',
//           coordinates: [
//             [
//               [120.16822524000001, 30.296452849999998],
//               [120.16172812, 30.296452849999984],
//               [120.16172812, 30.293130509999994],
//               [120.16822524000001, 30.29313050999998],
//               [120.16822524000001, 30.296452849999998],
//             ],
//           ],
//         },
//       },
//     ],
//     gid: 9383,
//   },
// });
// echarts.registerMap('test', {
//   geoJSON: {
//     type: 'FeatureCollection',
//     features: [
//       {
//         type: 'Feature',
//         id: 8231,
//         properties: {},
//         geometry: {
//           type: 'Polygon',
//           coordinates: [
//             [
//               [120.16306074, 30.29408794],
//               [120.16363005, 30.2942705],
//               [120.16368875, 30.29414299],
//               [120.16366523, 30.29405352],
//               [120.16316408, 30.29389418],
//               [120.16306678, 30.29397119],
//               [120.16306338, 30.29397746],
//               [120.16306074, 30.29408794],
//             ],
//           ],
//         },
//         bbox: [120.16306074, 30.29389418, 120.16368875, 30.2942705],
//       },
//     ],
//     gid: 5393,
//   },
// });
