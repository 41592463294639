const provinceCords = {
  台湾: [121.509062, 25.044332],
  河北: [114.502461, 38.045474],
  山西: [112.549248, 37.857014],
  内蒙古: [111.670801, 40.818311],
  辽宁: [123.429096, 41.796767],
  吉林: [125.3245, 43.886841],
  黑龙江: [126.642464, 45.756967],
  江苏: [118.767413, 32.041544],
  浙江: [120.153576, 30.287459],
  安徽: [117.283042, 31.86119],
  福建: [119.306239, 26.075302],
  江西: [115.892151, 28.676493],
  山东: [117.000923, 36.675807],
  河南: [113.665412, 34.757975],
  湖北: [114.298572, 30.584355],
  湖南: [112.982279, 28.19409],
  广东: [113.280637, 23.125178],
  广西: [108.320004, 22.82402],
  海南: [110.33119, 20.031971],
  四川: [104.065735, 30.659462],
  贵州: [106.713478, 26.578343],
  云南: [102.712251, 25.040609],
  西藏: [91.132212, 29.660361],
  陕西: [108.948024, 34.263161],
  甘肃: [103.823557, 36.058039],
  青海: [101.778916, 36.623178],
  宁夏: [106.278179, 38.46637],
  新疆: [87.617733, 43.792818],
  北京: [116.405285, 39.904989],
  天津: [117.190182, 39.125596],
  上海: [121.472644, 31.231706],
  重庆: [106.504962, 29.533155],
  香港: [114.173355, 22.320048],
  澳门: [113.54909, 22.198951],
};
export const provinceRegionMap = {
  台湾: '台湾省',
  河北: '河北省',
  山西: '山西省',
  内蒙古: '内蒙古自治区',
  辽宁: '辽宁省',
  吉林: '吉林省',
  黑龙江: '黑龙江省',
  江苏: '江苏省',
  浙江: '浙江省',
  安徽: '安徽省',
  福建: '福建省',
  江西: '江西省',
  山东: '山东省',
  河南: '河南省',
  湖北: '湖北省',
  湖南: '湖南省',
  广东: '广东省',
  广西: '广西壮族自治区',
  海南: '海南省',
  四川: '四川省',
  贵州: '贵州省',
  云南: '云南省',
  西藏: '西藏自治区',
  陕西: '陕西省',
  甘肃: '甘肃省',
  青海: '青海省',
  宁夏: '宁夏回族自治区',
  新疆: '新疆维吾尔自治区',
  北京: '北京市',
  天津: '天津市',
  上海: '上海市',
  重庆: '重庆市',
  香港: '香港特别行政区',
  澳门: '澳门特别行政区',
};
export function findProvinceCord(name: string) {
  const res = provinceCords[name];
  if (!res) {
    console.error(`can not find ${name} in provinceCords`);
  }
  return res;
}
