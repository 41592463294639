import './utils/flexible'
import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import App from './App'
import { Iconstyle } from './assets/icon/iconfont'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  // <React.StrictMode>
  // <Iconstyle />
  <App />
  // </React.StrictMode>
)
