import { FC, useEffect, useRef } from 'react';
import { Building } from './interface';

interface CampusMapProps {
  className?: string;
  center: number[];
  zoom: number;
  AMap: any;
  building: Building;
  buildings: Building[];
}
const CampusMap: FC<CampusMapProps> = (props) => {
  const { className, center, zoom, building, AMap, buildings } = props;
  const mapRef = useRef<any>();
  const amapGeoJSONRef = useRef<any>();
  const amapContainerRef = useRef<any>();
  const buildingLayerRef = useRef<any>();
  useEffect(() => {
    if (mapRef.current) {
      mapRef.current.setZoomAndCenter(zoom, center, true);
      return;
    }
    mapRef.current = new AMap.Map(amapContainerRef.current, {
      center,
      zoom,
      // terrain: true,
      showBuildingBlock: true,
      viewMode: '3D',
      pitch: 30,
      dragEnable: false,
      zoomEnable: false,
      pitchEnable: false,
      rotateEnable: false,
      keyboardEnable: false,
      doubleClickZoom: false,
      labelRejectMask: true,
    });
  }, [zoom, center]);
  useEffect(() => {
    if (!building) {
      return;
    }
    if (buildingLayerRef.current) {
      mapRef.current.remove(buildingLayerRef.current);
    }
    const marker = new AMap.LabelMarker({
      position: building.point,
      text: {
        content: building.name,
        style: {
          fontSize: 18,
          // strokeColor: 'rgba(255,255,255,1)',
          // fillColor: '#fff',
        },
        direction: 'right',
      },
      icon: {
        image: 'https://a.amap.com/jsapi_demos/static/demo-center/icons/poi-marker-default.png',
        size: [39, 51],
        imageSize: [39, 51],
      },
      anchor: 'bottom-center',
    });
    buildingLayerRef.current = marker;
    mapRef.current.add(marker);
    // const options = {
    //   hideWithoutStyle: true, //是否隐藏设定区域外的楼块
    //   areas: buildings.map((b) => {
    //     const colors = building.name === b.name ? ['ffffff00', 'ffffcc00'] : ['fff', 'fff'];
    //     return {
    //       //围栏1
    //       //visible:false,//是否可见
    //       rejectTexture: true, //是否屏蔽自定义地图的纹理
    //       color1: colors[0], //楼顶颜色
    //       color2: colors[1], //楼面颜色
    //       path: b.coords,
    //     };
    //   }),
    // };
    // console.log(building.name);
    // if (buildingLayerRef.current) {
    //   // buildingLayerRef.current.setStyle({ areas: null });
    //   buildingLayerRef.current.setStyle(options);
    //   // return;
    //   // mapRef.current.remove(buildingLayerRef.current);
    //   // console.log('remove', Date.now());
    //   // buildingLayerRef.current = null;
    // } else {
    //   const buildingLayer = new AMap.Buildings({ zIndex: 130 });

    //   buildingLayer.setStyle(options);
    //   buildingLayerRef.current = buildingLayer;
    //   mapRef.current.addLayer(buildingLayer);
    // }
    //还可以自定义getMarker和getPolyline
    // const buildingLayer = new AMap.Buildings({ zIndex: 130, zooms: [16, 20] });

    // buildingLayer.setStyle(options);
    // buildingLayerRef.current = buildingLayer;
    // mapRef.current.addLayer(buildingLayer);
    // console.log('add', Date.now(), building);
  }, [building, buildings]);
  return <div ref={amapContainerRef} className={className}></div>;
};
CampusMap.displayName = 'CampusMap';
export default CampusMap;
