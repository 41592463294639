import { FC, useMemo } from 'react';
import { barLabelFontSize, lineLabelFontSize } from '../../../utils/var';
import Echarts from '../../ECharts';
import useCarouselData from '../../hooks/useCarouselData';

interface MonthPopluationProps {
  data: any[];
}
const MonthPopluation: FC<MonthPopluationProps> = ({ data }) => {
  // const [activeData] = useCarouselData({ data, interval: 4000 });
  const option = useMemo(() => {
    // const legendsSelected: any = {};
    const legends = data.map((d) => {
      // legendsSelected[d.name] = d.name === activeData.name;
      return d.name;
    });
    const xAsisItems = data[0].data.map((d) => d.name);
    return {
      //画布背景色设置
      backgroundColor: 'transparent',
      title: {
        show: false,
      },
      tooltip: {
        show: false,
      },
      legend: {
        show: true,
        data: legends,
        right: 10,
        textStyle: {
          color: '#fff',
        },
        // selected: legendsSelected,
      },
      toolbox: {
        show: false,
      },
      calculable: true,
      grid: {
        show: false,
      },
      xAxis: [
        {
          data: xAsisItems,
          type: 'category',
          nameTextStyle: {
            color: '#fff',
          },
          axisLine: {
            //y轴
            show: false,
          },
          axisTick: {
            show: false,
          },
          axisLabel: {
            color: '#ffff',
          },
          splitArea: {
            show: false,
          },
        },
      ],
      yAxis: [
        {
          type: 'value',
          name: '人数',
          minInterval: 1,
          nameLocation: 'end',
          axisLabel: {
            color: '#ffff',
          },
          axisLine: {
            //y轴
            show: false,
          },
          axisTick: {
            //刻度线
            show: false,
          },
          splitLine: {
            //网格线
            show: true,
            lineStyle: {
              color: 'rgba(255,255,255,.2)',
            },
          },
          splitArea: {
            show: false,
          },
          min(value) {
            return Math.max(value.min - 10, 0);
          },
        },
      ],
      series: data.map((rowData) => {
        return {
          name: rowData.name,
          type: 'line',
          data: rowData.data,
          label: {
            show: true,
            color: '#fff',
            position: 'top',
            formatter: '{c}',
            fontSize: lineLabelFontSize,
          },
          endLabel: {
            show: true,
            color: '#fff',
            formatter: '{a}',
          },
        };
      }),
    };
  }, [data]);

  return <Echarts height={400} option={option} />;
};
MonthPopluation.displayName = 'MonthPopluation';
export default MonthPopluation;
