import { BorderBox10, BorderBox11 } from '@jiaminghi/data-view-react';
import { FC, useMemo } from 'react';
import AnimatedNumber from '../../Animatied/Number';
import AnimatedScrollIntoText from '../../Animatied/ScrollIntoText';
import useCarouselData from '../../hooks/useCarouselData';
import { ModuleTitle } from '../../ModuleTitle';
import Page from '../../Page';
import ValueRingRow from '../../ValueRingRow';
import FiveLoveSymbol from './FiveLoveSymbol';
import Grade from './Grade';
import styles from './index.module.scss';
import MonthPopluation from './MonthPopluation';
import Population from './Population';
import Themes from './Themes';
import Trigger from './Trigger';
import axios from 'axios';
import { useRequest } from 'ahooks';
import withRemoteData, { replaceGlobalDataWith } from '../../../utils/withRemoteData';

interface FiveLoveProps {}
function request() {
  // return axios.get('/api/large/care').then(({ data: { data } }) => {
  //   window.__data__.care.trigger[0].value = data.truancy;
  //   window.__data__.care.trigger[1].value = data.lateNight;
  //   window.__data__.care.trigger[2].value = data.infraction;
  //   window.__data__.care.trigger[3].value = data.specialEvents;
  //   window.__data__.care.trigger[4].value = data.threeOrFourStar;

  //   return data;
  // });
  return Promise.all([
    replaceGlobalDataWith('/api/large/careCnt', 'care.count'),
    replaceGlobalDataWith('/api/large/careSanhe', 'care.sanhe'),
    replaceGlobalDataWith('/api/large/careThemeLarge', 'care.themes'),
    replaceGlobalDataWith('/api/large/careMonth5Plus', 'care.month'),
    replaceGlobalDataWith('/api/large/careTargetType', 'care.trigger'),
    replaceGlobalDataWith('/api/large/careMonth', 'care.monthPopluation'),
    replaceGlobalDataWith('/api/large/carePopulation', 'care.population'),
  ]);
}
const FiveLove: FC<FiveLoveProps> = () => {
  const { monthPopluation, sanhe, count, themes, population, month, trigger } = window.__data__.care;
  return (
    <Page
      title={
        <>
          <FiveLoveSymbol count={5} />
          关爱
        </>
      }
    >
      <div className={styles.main}>
        <div className={styles.left}>
          <div className={styles.leftTop}>
            <BorderBox11 title="关爱次数">
              {count.map((data) => (
                <ValueRingRow
                  key={data.name}
                  height={60}
                  title={data.name}
                  value={data.value}
                  percentage={data.percentage}
                />
              ))}
            </BorderBox11>
          </div>
          <div className={styles.leftBottom}>
            <BorderBox11 title="关爱主题">
              <Themes data={themes} />
            </BorderBox11>
          </div>
        </div>
        <div className={styles.center}>
          <div className={styles.centerTop}>
            <Population data={population} />
          </div>
          <div className={styles.centerBottom}>
            <BorderBox11 title="月度变化">
              <MonthPopluation data={monthPopluation} />
            </BorderBox11>
          </div>
        </div>
        <div className={styles.right}>
          <div className={styles.rightTop}>
            <BorderBox11 title="当月5♡+">
              <Grade data={month} />
            </BorderBox11>
          </div>
          <div className={styles.rightCenter}>
            <BorderBox11 title="5♡+触发类型">
              <Trigger data={trigger} />
            </BorderBox11>
          </div>
          <div className={styles.rightBottom}>
            <BorderBox11 title="5♡三核">
              {sanhe.map((data) => (
                <ValueRingRow
                  key={data.name}
                  height={60}
                  title={data.name}
                  value={data.value}
                  percentage={data.percentage}
                />
              ))}
            </BorderBox11>
          </div>
        </div>
      </div>
    </Page>
  );
};
FiveLove.displayName = 'FiveLove';
export default withRemoteData(FiveLove, request);
