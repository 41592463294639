import { FC, useMemo } from 'react';
import { ScrollBoard } from '@jiaminghi/data-view-react';

interface VangardListProps {
  data: any[];
}
const VangardList: FC<VangardListProps> = ({ data }) => {
  const config = useMemo(() => {
    return {
      // 表头背景色
      headerBGC: '#443dc5',
      // 奇数行背景色
      oddRowBGC: '#09184F',
      // 偶数行背景色
      evenRowBGC: '#070C34',
      // 行号
      index: false,
      // 行号表头
      indexHeader: '序号',
      // 宽度
      columnWidth: [150, 180, 180],
      // 对其方式
      align: ['center', 'center', 'center'],
      // 表行数
      rowNum: 10,
      header: ['姓名', '发展状态', '先锋岗位'],
      data,
      // carousel: 'page',
    };
  }, [data]);
  return (
    <ScrollBoard
      config={config}
      style={{
        width: '490px',
        height: '450px',
        margin: '0 auto',
      }}
    />
  );
};
VangardList.displayName = 'VangardList';
export default VangardList;
