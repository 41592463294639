import './Maps';
import * as _ from 'lodash';
import * as React from 'react';
import * as echarts from 'echarts';
//@ts-ignore
import macarons from './themes/macarons';
interface EChartsComponentProps {
  echartsRef?: any;
  className?: string;
  style?: React.CSSProperties;
  option?: any;
  notMerge?: boolean;
  lazyUpdate?: boolean;
  theme?: string | Object;
  /**echars初始化使用，didMount之后改变无效 */
  devicePixelRatio?: number;
  width?: any;
  height?: any;
  loading?: boolean;
  onSelect?(event: any): any;
  onUnSelect?(event: any): any;
  onInit?(ec: any): any;
  onClick?(event: any): any;
  onZRClick?(event: any): any;
}
interface EChartsComponentState {}
const instances = new Map<number, Echarts>();
const handleWindowResize = _.debounce(() => {
  instances.forEach((instance) => {
    instance.resize();
  });
}, 300);
let autoIncreaseId = 0;
window.addEventListener('resize', handleWindowResize);
echarts.registerTheme('macarons', macarons);

const eventNamePropsMap: { [key: string]: string } = {
  unselect: 'onUnSelect',
};
function upperFirst(str: string) {
  return str[0].toUpperCase() + str.substring(1);
}
const eventNames = ['select', 'unselect', 'click'];
class Echarts extends React.Component<EChartsComponentProps, EChartsComponentState> {
  static defaultProps: Partial<EChartsComponentProps> = {
    notMerge: false,
    lazyUpdate: false,
    loading: false,
    theme: 'macarons',
  };
  container: HTMLDivElement;
  echarts: any;
  private id = -1;
  componentDidMount() {
    const { devicePixelRatio, width, height, option, loading, onInit, onZRClick } = this.props;
    this.echarts = echarts.init(this.container, this.props.theme, {
      devicePixelRatio,
      width,
      height,
      renderer: 'canvas',
    });
    if (option) {
      this.echarts.setOption(option, this.props.notMerge, this.props.lazyUpdate);
    }
    if (loading) {
      this.echarts.showLoading();
    }
    this.events(this.props);
    this.id = autoIncreaseId++;
    instances.set(this.id, this);
    this.setRefIfNeeded();
    if (typeof onZRClick === 'function') {
      this.echarts.getZr().on('click', onZRClick);
    }
    if (typeof onInit === 'function') {
      onInit(this.echarts);
    }
  }
  componentDidUpdate(prevProps: EChartsComponentProps) {
    const { loading, option, height, width, onZRClick } = this.props;
    if (prevProps.option !== option) {
      this.echarts.setOption(option, this.props.notMerge, this.props.lazyUpdate);
    }
    if (prevProps.height !== height || prevProps.width !== width) {
      this.echarts.resize({ height, width });
    }
    if (prevProps.loading !== loading) {
      if (loading) {
        this.echarts.showLoading();
      } else {
        this.echarts.hideLoading();
      }
    }
    if (prevProps.onZRClick !== onZRClick) {
      this.echarts.getZr().off('click', prevProps.onZRClick);
      if (typeof onZRClick === 'function') {
        this.echarts.getZr().on('click', onZRClick);
      }
    }
    this.events(this.props, prevProps);
    this.setRefIfNeeded(prevProps);
  }
  componentWillUnmount() {
    instances.delete(this.id);
    this.removeEvents();
    this.echarts.getZr().off('click', this.props.onZRClick);
    this.echarts.dispose();
  }
  render() {
    return <div ref={(r) => (this.container = r)} className={this.props.className} style={this.props.style}></div>;
  }
  setRefIfNeeded(prevProps?: any) {
    const { echartsRef } = this.props;
    if (echartsRef && prevProps?.echartsRef !== echartsRef) {
      echartsRef.current = this.echarts;
    }
  }
  events(props: any, prevProps?: any) {
    eventNames.forEach((eventName) => {
      const eventPropKey = eventNamePropsMap[eventName] || `on${upperFirst(eventName)}`;
      const callbackInProp = props[eventPropKey];
      const callbackInPrevProp = prevProps && prevProps[eventPropKey];
      this.event(eventName, callbackInProp, callbackInPrevProp);
    });
  }
  removeEvents() {
    const { props } = this as any;
    eventNames.forEach((eventName) => {
      const eventPropKey = eventNamePropsMap[eventName] || `on${upperFirst(eventName)}`;
      const callbackInProp = props[eventPropKey];
      this.echarts.off(eventName, callbackInProp);
    });
  }
  event(eventName: string, listener: any, prevListener?: any) {
    if (typeof prevListener === 'function' && prevListener !== listener) {
      this.echarts.off(eventName, prevListener);
    }
    if (typeof listener === 'function') {
      this.echarts.on(eventName, listener);
    }
  }
  resize() {
    this.echarts.resize();
  }
  getEchartsWidth = () => {
    return this.echarts.getWidth();
  };
}
export default Echarts;
