import { FC } from 'react';
import { BorderBox10 } from '@jiaminghi/data-view-react';
import styles from './index.module.scss';
import classNames from 'classnames';
import Ring from './Ring';

interface ValueRingRowProps {
  className?: string;
  title: React.ReactNode;
  value: any;
  valueClassName?: string;
  percentage: number;
  height?: number;
  marginBottom?: number;
}
const ValueRingRow: FC<ValueRingRowProps> = ({
  title,
  value,
  percentage,
  className,
  height,
  marginBottom,
  valueClassName,
}) => {
  return (
    <div className={classNames(styles.wrap, className)} style={{ height, marginBottom }}>
      <div className={styles.title}>{title}</div>
      {
        title=='寝室'?<div className={styles.marBox}><div className={classNames(styles.valueMar, valueClassName)}>{value}</div></div>:<div className={classNames(styles.value, valueClassName)}>{value}</div>
      }
      
      {
        percentage?<div className={styles.ring}>
                      <Ring value={percentage} height={height} />
                    </div>:<div></div>
                    }
    </div>
  );
};
ValueRingRow.displayName = 'ValueRingRow';
ValueRingRow.defaultProps = {
  height: 70,
  marginBottom: 12,
};
export default ValueRingRow;
