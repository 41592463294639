import echarts from 'echarts/lib/echarts';
import _ from 'lodash';
import { findProvinceCord, provinceRegionMap } from '../../../ECharts/Maps/chinaCords';

const lineColors = {
  firstFive: {
    type: 'linear',
    x: 0,
    y: 0,
    x2: 0,
    y2: 1,
    colorStops: [
      {
        offset: 0,
        color: 'cyan',
      },
      {
        offset: 1,
        color: 'blue',
      },
    ],
    global: false,
  },
  lastFive: {
    type: 'linear',
    x: 0,
    y: 0,
    x2: 0,
    y2: 1,
    colorStops: [
      {
        offset: 0,
        color: '#F58158',
      },
      {
        offset: 1,
        color: '#934f91',
      },
    ],
    global: false,
  },
  default: {
    type: 'linear',
    x: 0,
    y: 0,
    x2: 0,
    y2: 1,
    colorStops: [
      {
        offset: 0,
        color: '#fff',
      },
      {
        offset: 1,
        color: '#fff',
      },
    ],
    global: false,
  },
};
const pointColors = {
  firstFive: '#fff',
  lastFive: '#fff',
  default: '#fff',
};
const highlightStyles = {
  firstFive: {
    borderColor: '#FFD1A3',
    areaColor: 'rgba(60, 157, 228, .7)',
    borderWidth: 1,
    shadowBlur: 5,
    shadowColor: 'rgba(135,138,255,.5)',
  },
  lastFive: {
    borderColor: '#FFD1A3',
    areaColor: 'rgba(255,109,135,.5)',
    borderWidth: 1,
    shadowBlur: 5,
    shadowColor: 'rgba(135,138,255,.5)',
  },
  default: {
    borderColor: '#FFD1A3',
    areaColor: 'rgba(102,105,240,.3)',
    borderWidth: 1,
    shadowBlur: 5,
    shadowColor: 'rgba(135,138,255,.5)',
  },
};
//hong F58158 lan 58B3CC
export const mapOptions = (sortedData) => {
  const HandZhouCoords = findProvinceCord('浙江');
  const lines = [];
  const regions = [];
  const points = sortedData.map((province, index) => {
    const isFisrtFive = index < 5;
    const isLastFive = sortedData.length - index <= 5;
    const colorKey = isFisrtFive ? 'firstFive' : isLastFive ? 'lastFive' : 'default';
    lines.push({
      fromName: province.name,
      toName: '杭州',
      coords: [findProvinceCord(province.name), HandZhouCoords],
      lineStyle: {
        color: lineColors[colorKey],
      },
    });
    regions.push({
      name: provinceRegionMap[province.name],
      itemStyle: highlightStyles[colorKey],
    });
    return {
      name: province.name,
      value: findProvinceCord(province.name),
      symbolSize: 2,
      itemStyle: {
        color: pointColors[colorKey],
      },
    };
  });
  return {
    title: {
      show: true,
      text: '生源分布与绩点统计',
      left: '10px',
      top: '13px',
      textStyle: {
        fontSize: 24,
        color: '#fff',
      },
    },
    legend: {
      show: false,
    },
    geo: {
      silent: true,
      nameMap: {
        china: '中国',
      },
      map: 'china',
      label: {
        emphasis: {
          show: false,
        },
      },
      zoom: 1.2,
      itemStyle: {
        normal: {
          borderColor: 'rgba(255,209,163, .5)', //区域边框颜色
          areaColor: 'rgba(73,86,166,.1)', //区域颜色
          borderWidth: 0.5, //区域边框宽度
          shadowBlur: 5,
          shadowColor: 'rgba(107,91,237,.7)',
        },
        emphasis: highlightStyles.default,
      },
      regions,
    },
    series: [
      {
        name: '地点',
        type: 'effectScatter',
        coordinateSystem: 'geo',
        zlevel: 2,
        rippleEffect: {
          brushType: 'stroke',
        },
        label: {
          show: true,
          formatter: '{b}',
          position: 'right',
          color: '#fff',
        },
        symbolSize: 2,
        showEffectOn: 'render',
        // itemStyle: {
        //   color: '#46bee9',
        // },
        data: points,
      },
    ],
  };
};
