import { FC, useMemo } from 'react';
import { lineEndLabel, lineLabelFontSize } from '../../../utils/var';
import Echarts from '../../ECharts';

interface ControlMonthlyChangeProps {
  data: any[];
}
const ControlMonthlyChange: FC<ControlMonthlyChangeProps> = ({ data }) => {
  const option = useMemo(() => {
    const xAsisItems = data.map((d) => d.name);
    return {
      //画布背景色设置
      backgroundColor: 'transparent',
      title: {
        show: false,
      },
      tooltip: {
        show: false,
      },
      legend: {
        show: false,
      },
      toolbox: {
        show: false,
      },
      calculable: true,
      grid: {
        show: false,
      },
      xAxis: [
        {
          data: xAsisItems,
          type: 'category',
          nameTextStyle: {
            color: '#fff',
          },
          axisLine: {
            //y轴
            show: false,
          },
          axisTick: {
            show: false,
          },
          axisLabel: {
            color: '#ffff',
          },
          splitArea: {
            show: false,
          },
        },
      ],
      yAxis: [
        {
          type: 'value',
          name: '人数',
          minInterval: 1,
          nameLocation: 'end',
          axisLabel: {
            color: '#ffff',
          },
          axisLine: {
            //y轴
            show: false,
          },
          axisTick: {
            //刻度线
            show: false,
          },
          splitLine: {
            //网格线
            show: true,
            lineStyle: {
              color: 'rgba(255,255,255,.2)',
            },
          },
          splitArea: {
            show: false,
          },
          min(value) {
            return Math.max(value.min - 10, 0);
          },
        },
      ],
      series: [
        {
          name: '管控人数月度变化',
          type: 'line',
          data: data,
          label: {
            show: true,
            color: '#fff',
            position: 'top',
            formatter: '{c}',
            fontSize: lineLabelFontSize,
          },
        },
      ],
    };
  }, [data]);
  return <Echarts height={420} option={option} />;
};
ControlMonthlyChange.displayName = 'ControlMonthlyChange';
export default ControlMonthlyChange;
