import { FC, useMemo } from 'react';
import AnimatedScrollIntoText from '../../Animatied/ScrollIntoText';
import DataName from '../../Data/Name';
import DataValue from '../../Data/Value';
import Echarts from '../../ECharts';
import useCarouselData from '../../hooks/useCarouselData';
import styles from './Campus.module.scss';

interface CampusProps {
  data: any[];
  total: any;
}
const Campus: FC<CampusProps> = ({ data, total }) => {
  // const [activeData, active] = useCarouselData({ data, interval: 5000 });
  const options = useMemo(() => {
    const points = data.map((rowData, index) => {
      const isActive = false; //active === index;
      const point: any = {
        name: rowData.name,
        value: rowData.coords,
        symbolSize: 2,
        itemStyle: {
          color: isActive ? 'red' : '#46bee9',
        },
        label: {
          fontSize: isActive ? 16 : 14,
          color: isActive ? '#fff' : '#fff',
          position: rowData.labelPosition || 'left',
        },
      };
      return point;
    });
    return {
      title: {
        show: false,
        text: '生源图',
        left: 'center',
        textStyle: {
          color: '#fff',
        },
      },
      legend: {
        show: false,
      },
      geo: {
        silent: true,
        // regions: [
        //   {
        //     name: activeData.city,
        //     itemStyle: {
        //       borderColor: '#FFD1A3',
        //       areaColor: 'rgba(102,105,240,.3)',
        //       borderWidth: 1,
        //       shadowBlur: 5,
        //       shadowColor: 'rgba(135,138,255,.5)',
        //     },
        //   },
        // ],
        nameMap: {
          china: '中国',
        },
        map: 'campusCitys',
        label: {
          show: true,
          color: '#fff',
        },
        zoom: 1.2,
        itemStyle: {
          normal: {
            borderColor: 'rgba(255,209,163, .5)', //区域边框颜色
            areaColor: 'rgba(73,86,166,.1)', //区域颜色
            borderWidth: 0.5, //区域边框宽度
            shadowBlur: 5,
            shadowColor: 'rgba(107,91,237,.7)',
          },
        },
      },
      series: [
        {
          name: '地点',
          type: 'effectScatter',
          coordinateSystem: 'geo',
          zlevel: 2,
          rippleEffect: {
            brushType: 'stroke',
          },
          label: {
            show: true,
            formatter: '{b}',
            position: 'left',
            color: '#fff',
          },
          symbolSize: 2,
          showEffectOn: 'render',
          data: points,
        },
      ],
    };
  }, []);
  return (
    <div style={{ paddingTop: 70 }}>
      <div className={styles.oneRowTitle}>
        <DataName className={styles.headerItemTitle}>今日在校总人数</DataName>
        <DataValue className={styles.headerItemCount}>
          <span>{total}</span>
          {/* <span>1758</span> */}
          <span>人</span>
        </DataValue>
      </div>
      {data.map((d) => {
        return (
          <div key={d.name} className={styles.oneRowTitle}>
            <DataName>{d.name}</DataName>
            <DataValue>
              <span>{d.students}</span>
              <span>人</span>
            </DataValue>
          </div>
        );
      })}
      <Echarts option={options} height={300} />
    </div>
  );
};
Campus.displayName = 'Campus';
export default Campus;
