import _ from 'lodash';
import { FC, useMemo } from 'react';
import sum from '../../../utils/sum';
import AnimatedNumber from '../../Animatied/Number';
import AnimatedScrollIntoText from '../../Animatied/ScrollIntoText';
import DLBlock from '../../DLBlock';
import useCarouselData from '../../hooks/useCarouselData';
import { ModuleTitle } from '../../ModuleTitle';
import Page from '../../Page';
import { partyBuildingData } from './data';
import styles from './index.module.scss';
import _BorderBox11 from '../../BorderBox11';
import VangardList from './VangardList';
import Fortress from './Fortress';
import HalfMonth from './HalfMonth';
import TH from './TH';
import Tree from './Tree';
import DataName from '../../Data/Name';
import DataValue from '../../Data/Value';
import withRemoteData, { replaceGlobalDataWith } from '../../../utils/withRemoteData';
import axios from 'axios';

const BorderBox11: any = _BorderBox11;

interface PartyBuildingProps {}
function request() {
  return Promise.all([
    axios.get('/api/large/partyBuildingDataCnt').then(({ data: { data } }) => {
      if (data) {
        Object.assign(window.__data__.partyBuildingData, data);
      }
      return data;
    }),
    replaceGlobalDataWith('/api/large/vanguardLarge', 'partyBuildingData.vanguard'),
  ]);
}
const PartyBuilding: FC<PartyBuildingProps> = () => {
  const { fullPartyMember, probationaryPartyMember, joinPartyActivists, applicant, vanguard, fortress, speech, tree } =
    partyBuildingData;
  return (
    <Page title="1号先锋">
      <div className={styles.header}>
        <DLBlock
          height={160}
          width={300}
          title="正式党员数: "
          titleNumber={fullPartyMember.total}
          descs={[`本科生: ${fullPartyMember.undergraduate}`, `研究生: ${fullPartyMember.postgraduate}`]}
        />
        <DLBlock
          height={160}
          width={300}
          title="预备党员数: "
          titleNumber={probationaryPartyMember.total}
          descs={[
            `本科生: ${probationaryPartyMember.undergraduate}`,
            `研究生: ${probationaryPartyMember.postgraduate}`,
          ]}
        />
        <DLBlock
          height={160}
          width={300}
          title="入党积极分子数: "
          titleNumber={joinPartyActivists.total}
          descs={[`本科生: ${joinPartyActivists.undergraduate}`, `研究生: ${joinPartyActivists.postgraduate}`]}
        />
        <DLBlock
          height={160}
          width={300}
          title="申请入党数: "
          titleNumber={applicant.total}
          descs={[`本科生: ${applicant.undergraduate}`, `研究生: ${applicant.postgraduate}`]}
        />
      </div>
      <div className={styles.main}>
        <div className={styles.left}>
          <div className={styles.leftTop}>
            <BorderBox11 title="先锋实践平台">
              <div className={styles.oneRowTitle}>
                <DataName isTotal>1号先锋总人数</DataName>
                <DataValue isTotal>
                  <span>{vanguard.total}</span>
                  <span>人</span>
                </DataValue>
              </div>
              {vanguard.data.map((d) => {
                return (
                  <div key={d.name} className={styles.oneRowTitle}>
                    <DataName>{d.name}</DataName>
                    <DataValue>
                      <span>{d.value}</span>
                      <span>人</span>
                    </DataValue>
                  </div>
                );
              })}
              <VangardList data={vanguard.list} />
            </BorderBox11>
          </div>
        </div>
        <div className={styles.center}>
          <div className={styles.centerTop}>
            <Tree data={tree} />
          </div>
          <div className={styles.centerBottom}>
            <Fortress data={fortress} />
          </div>
        </div>
        <div className={styles.right}>
          <BorderBox11 title="理论学习平台">
            {speech.data.map((d) => {
              return (
                <div key={d.name} className={styles.oneRowTitle}>
                  <DataName isTotal={d.isTotal}>{d.name}</DataName>
                  <DataValue isTotal={d.isTotal}>{d.value}</DataValue>
                </div>
              );
            })}
            <div className={styles.speechListTitle}>红色半月谈清单</div>
            <HalfMonth data={speech.halfMonth} />
            <div className={styles.speechListTitle}>红色星期二清单</div>
            <TH data={speech.th} />
          </BorderBox11>
        </div>
      </div>
    </Page>
  );
};
PartyBuilding.displayName = 'PartyBuilding';
export default withRemoteData(PartyBuilding, request);
